import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { IonContent, IonPage } from "@ionic/react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { PrimaryButton } from "../../components/PrimaryButton";

export const ActivationPage = () => {
  const { t, i18n } = useTranslation();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const [isActivated, setIsActivated] = useState<any>(null);
  useEffect(() => {
    i18n.changeLanguage(query.get("lang") || "");

    if (query.get("id")) {
      const formdata = new FormData();
      formdata.append("id", query.get("id")?.toString() || "");

      const requestOptions: any = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      if (
        process.env.REACT_APP_API_URL &&
        process.env.REACT_APP_USER_ACTIVATION
      ) {
        fetch(
          process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_ACTIVATION,
          requestOptions
        ).then((response) => {
          if (response.status === 200) {
            setIsActivated(true);
          } else {
            setIsActivated(false);
          }
        });
      }
    }
  }, [query.get("id"), query.get("lang")]);
  return (
    <IonPage id="main-content">
      <IonContent className="ion-padding">
        <div className={styles.activation}>
          <img
            className={styles.activationLogo}
            src="/assets/images/logo.png"
            alt="logo"
          />
          <p className={styles.activationSubtitle}>
            {t("ActivationPage.subtitle")}
          </p>
          <img
            className={styles.activationImage}
            src="/assets/images/head.webp"
            alt="img"
          />
          {isActivated !== null ? (
            <h1 className={styles.activationHeader}>
              {isActivated
                ? t("ActivationPage.activated")
                : t("ActivationPage.incorrect")}
            </h1>
          ) : (
            <p>{t("ActivationPage.loading")}</p>
          )}
          <a href="/login" style={{ textDecoration: "none" }}>
            <PrimaryButton text={t("ActivationPage.login")} />
          </a>
        </div>
      </IonContent>
    </IonPage>
  );
};
