import { useCallback, useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import { ModalPencil } from "../ModalPencil";
import { ModalPlus } from "../ModalPlus";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { like } from "../../helpers/like";

const Column = ({ current, data, setRefresh }: any) => {
  const dateString = data.day;
  const currentDate = new Date();
  const currentDateString =
    ("0" + currentDate.getDate()).slice(-2) +
    "-" +
    ("0" + (currentDate.getMonth() + 1)).slice(-2) +
    "-" +
    currentDate.getFullYear();

  if (dateString === currentDateString) {
    current = true;
  } else {
    current = false;
  }

  const { t } = useTranslation();

  const week = [
    t("weekday.sun"),
    t("weekday.mon"),
    t("weekday.tue"),
    t("weekday.wed"),
    t("weekday.thu"),
    t("weekday.fri"),
    t("weekday.sat"),
  ];
  const date = new Date(data.day.split("-").reverse().join("-"));
  const day = date.getDate();
  const weekday = date.getDay();
  const [open, setOpen] = useState(false);
  return (
    <div className={`${styles.Column} ${current && styles.ColumnCurrent}`}>
      <div className={styles.ColumnHeader}>
        <div className={styles.ColumnDay}>{week[weekday]}</div>
        <div className={styles.ColumnDay}>{day}</div>
      </div>
      <div className={styles.ColumnContent}>
        {data.data.map((item: any, index: number) => (
          <Item key={"content" + index} data={item} setRefresh={setRefresh} />
        ))}
      </div>
      <div
        className={`${styles.ColumnBottom} ${
          current && styles.ColumnBottomCurrent
        }`}
        onClick={() => setOpen(true)}
      >
        <ReactSVG src="/assets/icon/plus.svg" />
      </div>
      <ModalPlus open={open} setOpen={setOpen} data={data} />
    </div>
  );
};

const Item = ({ data, setRefresh }: any) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className={styles.Item}>
        <div
          className={styles.ItemLove}
          onClick={(e) => {
            like(data.recipe.id);
            setRefresh(Date.now());
          }}
        >
          {data.recipe.isFavorite ? (
            <ReactSVG src="/assets/icon/love1.svg" />
          ) : (
            <ReactSVG src="/assets/icon/love0.svg" />
          )}
        </div>
        <img onClick={() => setOpen(true)} src={data.recipe.media} alt="img" />
        <p onClick={() => setOpen(true)}>{data.recipe.name}</p>
      </div>
      <ModalPencil
        open={open}
        setOpen={setOpen}
        id={data.recipe.id}
        delId={data.id}
        setRefresh={setRefresh}
      />
    </>
  );
};

export const WeeklyPlanEdit = ({ data, setRefresh, dates }: any) => {
  const arr: any = [];

  dates.forEach((item: any) => {
    arr.push({
      day: item,
      data: [],
    });
  });
  data.forEach((item: any) => {
    if (arr.find((i: any) => i.day === item.day)) {
      arr.find((i: any) => i.day === item.day).data.push(item);
      return;
    }
  });

  return (
    <div className={styles.Container}>
      {arr.map((item: any, index: number) => (
        <Column
          key={"column" + index}
          current={item.data[0]?.today}
          data={item}
          setRefresh={setRefresh}
        />
      ))}
    </div>
  );
};
