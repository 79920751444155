import { useEffect, useState } from "react";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { BottomBlob } from "../../components/BottomBlob";
import { ReactSVG } from "react-svg";
import { useLocation } from "react-router-dom";
import { Search } from "../../components/Search";
import { like } from "../../helpers/like";
import { Filters } from "../../components/Filters";
import { SearchMany } from "../../components/SearchMany";

export const RecipesListingIngredientsPage = () => {
  const { t } = useTranslation();
  const [arrIngredients, setArrIngredients] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [numItemsPerPage, setNumItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const getData = (reset?: any) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const body = new FormData();

    arrIngredients.forEach((ingredient) => {
      body.append("ingredients[]", ingredient.id);
    });
    const requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body,
    };
    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_RECIPES_INGREDIENT
    ) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES_INGREDIENT,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setTotalCount(res.data.totalCount);
          setNumItemsPerPage(res.data.numItemsPerPage);
          const prev = reset ? [] : [...data];
          if (res.data.items.recipes) {
            setData([...prev, ...res.data.items.recipes]);
          } else {
            setData([...prev, ...res.data.items]);
          }
        }
      });
    }
  };

  useEffect(() => {
    getData();
  }, [page]);
  const generateItems = () => {
    if (page * numItemsPerPage >= totalCount) return;
    setPage(page + 1);
  };
  useEffect(() => {
    getData(true);
  }, [arrIngredients]);

  return (
    <Menu title={t("RecipesListingIngredientsPage.title")}>
      <div className={styles.recipes}>
        <SearchMany
          arrIngredients={arrIngredients}
          setArrIngredients={setArrIngredients}
        />
        <div className={styles.recipesTiles}>
          {data.length
            ? data.map((d: any, key: number) => (
                <div key={key} className={styles.recipesTilesTileWrap}>
                  <div
                    className={styles.recipesTilesTileLove}
                    onClick={(e) => {
                      like(d.id);
                      setData(
                        data.map((item: any) => {
                          if (item.id === d.id) {
                            item.isFavorite = !item.isFavorite;
                          }
                          return item;
                        })
                      );
                    }}
                  >
                    {d.isFavorite ? (
                      <ReactSVG src="/assets/icon/love1.svg" />
                    ) : (
                      <ReactSVG src="/assets/icon/love0.svg" />
                    )}
                  </div>
                  <a href={`/recipe?id=${d.id}`}>
                    <div
                      className={styles.recipesTilesTile}
                      style={{ backgroundImage: `url(${d.media})` }}
                    />
                  </a>
                  <a href={`/recipe?id=${d.id}`}>
                    <p className={styles.recipesTilesTileTitle}>{d.title}</p>
                  </a>
                </div>
              ))
            : null}
        </div>
        {!data.length && (
          <p style={{ textAlign: "center", marginBottom: 32 }}>
            {t("noSearchResults")}
          </p>
        )}
        <p>{t("RecipesListingIngredientsPage.info")}</p>
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            generateItems();
            setTimeout(() => ev.target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <BottomBlob />
      </div>
    </Menu>
  );
};
