import { useEffect, useState } from "react";
import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { BottomBlob } from "../../components/BottomBlob";
import { ReactSVG } from "react-svg";
import { WeeklyPlanEdit } from "../../components/WeeklyPlanEdit";
import { WeeklyPlanVertical } from "../../components/WeeklyPlanVertical";
import {
  checkIsToday,
  checkIsTommorow,
  checkIsYesterday,
} from "../../helpers/time";
import { ModalPencil } from "../../components/ModalPencil";
import { Calendar } from "react-multi-date-picker";
import { addLeadingZero } from "../../helpers/addLeadingZero";
import { datesBetween } from "../../helpers/datesBetween";
import { IonButton, IonContent, IonPopover } from "@ionic/react";
import { useHistory, useLocation } from "react-router";

export const WeeklyPlanPage = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const history = useHistory();

  const { t } = useTranslation();
  const [view, setView] = useState(0);
  const [planRecipes, setPlanRecipes] = useState([]);
  const getPlanRecipes = (fromDate?: string, toDate?: string) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const x =
      !!fromDate && !!toDate ? `?fromDate=${fromDate}&toDate=${toDate}` : "";
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_RECIPES_PLAN) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_RECIPES_PLAN + x,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          res.data.plan.map((item: any) => {
            const day = new Date(item.day.split("-").reverse().join("-"));
            if (checkIsToday(day)) {
              item.dayTitle = t("DashboardPage.today");
              item.today = true;
            } else if (checkIsTommorow(day)) {
              item.dayTitle = t("DashboardPage.tomorrow");
            } else if (checkIsYesterday(day)) {
              item.dayTitle = t("DashboardPage.yesterday");
            } else {
              item.dayTitle = item.day.split("-").reverse().join("-");
            }
          });
          setPlanRecipes(res.data.plan);
        }
      });
    }
  };
  const [refresh, setRefresh] = useState(false);
  const [values, setValues] = useState<any>({});
  const dzisiaj = new Date();
  const zaTydzien = new Date();
  zaTydzien.setDate(zaTydzien.getDate() + 6);
  const dzisiajFormatted = `${dzisiaj.getDate().toString().padStart(2, "0")}.${(
    dzisiaj.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")} - ${zaTydzien.getDate().toString().padStart(2, "0")}.${(
    zaTydzien.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}`;

  const [displayDate, setDisplayDate] = useState<any>(dzisiajFormatted);
  const [dates, setDates] = useState<any>([]);
  useEffect(() => {
    if (
      values[0]?.day &&
      values[1]?.day &&
      values[0]?.month?.number &&
      values[1]?.month?.number &&
      values[0]?.year &&
      values[1]?.year
    ) {
      const start = `${addLeadingZero(values[0].day)}-${addLeadingZero(
        values[0].month.number
      )}-${values[0].year}`;
      const end = `${addLeadingZero(values[1].day)}-${addLeadingZero(
        values[1].month.number
      )}-${values[1].year}`;
      setDates(datesBetween(start, end));
      getPlanRecipes(start, end);
      getPlanRecipes(start, end);
      setDisplayDate(
        `${addLeadingZero(values[0].day)}.${addLeadingZero(
          values[0].month.number
        )} - ${addLeadingZero(values[1].day)}.${addLeadingZero(
          values[1].month.number
        )}`
      );
    } else {
      const start = `${dzisiaj.getDate().toString().padStart(2, "0")}-${(
        dzisiaj.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${dzisiaj.getFullYear()}`;

      const end = `${zaTydzien.getDate().toString().padStart(2, "0")}-${(
        zaTydzien.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${zaTydzien.getFullYear()}`;

      setDates(datesBetween(start, end));
      getPlanRecipes();
      getPlanRecipes();
    }
  }, [values, refresh]);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    query.get("showSuccess") ? setShowModal(true) : setShowModal(false);
    setTimeout(() => {
      setShowModal(false);
      history.push("/weeklyplan");
    }, 2000);
  }, [query.get("showSuccess")]);
  return (
    <Menu title={t("WeeklyPlanPage.title")}>
      <div className={styles.weekly}>
        <div className={styles.weeklyTopBar}>
          <div className={styles.weeklyTopBarDate}>
            <p
              className={styles.weeklyTopBarDateLabel}
              onClick={() => setCalendarOpen(!calendarOpen)}
            >
              {displayDate?.replace(/\./g, "/") || t("WeeklyPlanPage.calendar")}
            </p>
            {calendarOpen && (
              <>
                <Calendar
                  className={styles.weeklyTopBarDateCalendar}
                  value={values}
                  onChange={setValues}
                  range
                  rangeHover
                />
                <div
                  className={styles.weeklyTopBarDateCalendarOverlay}
                  onClick={() => setCalendarOpen(!calendarOpen)}
                />
              </>
            )}
          </div>
          <IonButton id="click-trigger" className={styles.popoverBtn}>
            <ReactSVG src="/assets/icon/info.svg" />
          </IonButton>
          <IonPopover
            trigger="click-trigger"
            triggerAction="click"
            className={styles.popover}
          >
            <div
              style={{
                padding: "32px",
              }}
              dangerouslySetInnerHTML={{ __html: t("WeeklyPlanPage.popup") }}
            />
          </IonPopover>
          <button
            className={`${styles.weeklyTopBarBtn} ${
              !view && styles.weeklyTopBarBtnActive
            }`}
            onClick={() => setView(0)}
          >
            <ReactSVG src="/assets/icon/calendarView2.svg" />
          </button>
          <button
            className={`${styles.weeklyTopBarBtn} ${
              view && styles.weeklyTopBarBtnActive
            }`}
            onClick={() => setView(1)}
          >
            <ReactSVG src="/assets/icon/calendarView3.svg" />
          </button>
        </div>

        <div className={styles.dashboardContent}>
          {view && dates.length ? (
            <WeeklyPlanVertical
              data={planRecipes}
              setRefresh={setRefresh}
              dates={dates}
            />
          ) : (
            <WeeklyPlanEdit
              data={planRecipes}
              setRefresh={setRefresh}
              dates={dates}
            />
          )}
        </div>

        <ModalPencil />
        {!!view && <BottomBlob />}
      </div>
      {showModal && (
        <div className={styles.modal}>{t("WeeklyPlanSelect.success")}</div>
      )}
    </Menu>
  );
};
