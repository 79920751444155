import { useEffect, useState } from "react";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { BottomBlob } from "../../components/BottomBlob";
import { ReactSVG } from "react-svg";
import { Search } from "../../components/Search";
import { like } from "../../helpers/like";

export const FavoritePage = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [numItemsPerPage, setNumItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const getData = (refresh?: any) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_FAVORITE_READ) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_FAVORITE_READ +
          `?page=${refresh ? 1 : page}`,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setTotalCount(res.data.totalCount);
          setNumItemsPerPage(res.data.numItemsPerPage);
          if (refresh) {
            setData(res.data.items);
          } else {
            setData([...data, ...res.data.items]);
          }
        }
      });
    }
  };
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (!(page === 1)) getData();
  }, [page]);
  useEffect(() => {
    setPage(1);
    getData(true);
    getData(true);
  }, [refresh]);
  const generateItems = () => {
    if (page * numItemsPerPage >= totalCount) return;
    setPage(page + 1);
  };
  return (
    <Menu title={t("FavoritePage.title")}>
      <div className={styles.recipes}>
        <Search />
        <div className={styles.recipesTiles}>
          {data.map((d: any, key: number) => (
            <div key={key} className={styles.recipesTilesTileWrap}>
              <div
                className={styles.recipesTilesTileLove}
                onClick={(e) => {
                  like(d.id);
                  setRefresh(!refresh);
                }}
              >
                {d.isFavorite ? (
                  <ReactSVG src="/assets/icon/love1.svg" />
                ) : (
                  <ReactSVG src="/assets/icon/love0.svg" />
                )}
              </div>
              <a href={`/recipe?id=${d.id}`}>
                <div
                  className={styles.recipesTilesTile}
                  style={{ backgroundImage: `url(${d.media})` }}
                />
              </a>
              <a href={`/recipe?id=${d.id}`}>
                <p className={styles.recipesTilesTileTitle}>{d.title}</p>
              </a>
            </div>
          ))}
        </div>
        <p>{t("FavoritePage.description")}</p>
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            generateItems();
            setTimeout(() => ev.target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <BottomBlob />
      </div>
    </Menu>
  );
};
