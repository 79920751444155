import {
  IonContent,
  IonHeader,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonRouterLink,
} from "@ionic/react";
import styles from "./style.module.scss";
import { ReactSVG } from "react-svg";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Accordion } from "../Accordion";
import { Capacitor } from "@capacitor/core";
import { SafeArea } from "capacitor-plugin-safe-area";
import { useTranslation } from "react-i18next";

const bottomItems = [
  {
    href: "/dashboard",
    icon: "assets/icon/menu2.svg",
    urls: ["/dashboard"],
  },
  {
    href: "/recipes",
    icon: "assets/icon/menu3.svg",
    urls: ["/recipes", "/recipeslisting", "/recipe"],
  },
  {
    href: "/favorite",
    icon: "assets/icon/menu1.svg",
    urls: ["/favorite"],
  },
  {
    href: "/weeklyplan",
    icon: "assets/icon/menu5.svg",
    urls: ["/weeklyplan"],
  },
  {
    href: "/ingredientlist",
    icon: "assets/icon/menu4.svg",
    urls: ["/ingredientlist"],
  },
];

const MenuItem = ({ title, icon, func, logout, accordion, items }: any) => {
  if (logout) {
    return (
      <IonMenuToggle>
        <div onClick={func} className={styles.menuItem}>
          <div></div>
          <p className={styles.menuItemTitle}>{title}</p>
          <img className={styles.menuItemIcon} src={icon} />
        </div>
      </IonMenuToggle>
    );
  } else if (accordion && items.length > 0) {
    return (
      <Accordion title={title} icon={icon} indentation>
        {items.map((item: any, index: number) => (
          <IonMenuToggle key={"itemToggl" + index}>
            <div
              onClick={item.func}
              className={`${styles.menuItem} ${styles.menuItemIndent}`}
            >
              <img className={styles.menuItemIcon} src={item.icon} />
              <p className={styles.menuItemTitle}>{item.title}</p>
              <ReactSVG
                className={styles.menuItemArrow}
                src="assets/icon/right.svg"
              />
            </div>
          </IonMenuToggle>
        ))}
      </Accordion>
    );
  } else {
    return (
      <IonMenuToggle>
        <div onClick={func} className={styles.menuItem}>
          <img className={styles.menuItemIcon} src={icon} />
          <p className={styles.menuItemTitle}>{title}</p>
          <ReactSVG
            className={styles.menuItemArrow}
            src="assets/icon/right.svg"
          />
        </div>
      </IonMenuToggle>
    );
  }
};
export const Menu = ({
  title,
  children,
  other,
  skip,
  hide,
  link,
  goToMenu,
}: any) => {
  const { t, i18n } = useTranslation();
  const [statusBarHeight, setStatusBarHeight] = useState<any>(0);
  const [sizes, setSizes] = useState<any>(0);
  useEffect(() => {
    if (Capacitor.getPlatform() === "ios") {
      SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
        setStatusBarHeight(statusBarHeight);
      });
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        setSizes(insets);
      });
    }
  }, []);
  const [avatar, setAvatar] = useState("");
  //---  GET USER DATA
  const [userName, setUserName] = useState<any>("");
  const getUserData = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_USER_READ) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_READ,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setUserName(res.data.name);
          i18n.changeLanguage(res.data.language);
        } else if (response.status === 401) {
          localStorage.removeItem("token");
          history.push("/login");
          history.go(0);
        } else if (response.status === 403) {
          if (history.location.pathname !== "/payment") {
            history.push("/payment");
            history.go(0);
          }
        }
      });
    }
  };
  //---
  const getAvatar = async () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_FAVORITE_CREATE
    ) {
      await fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_AVATAR_READ,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setAvatar(res.data.avatar);
        }
      });
    }
  };
  const uploadAvatar = async (e: any) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append("avatar", e.target.files[0]);

    const requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_AVATAR_CREATE) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_AVATAR_CREATE,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          getAvatar();
        }
      });
    }
  };
  useEffect(() => {
    getUserData();
    getAvatar();
  }, []);
  const history = useHistory();
  const items = [
    {
      title: t("Menu.items.0"),
      icon: "assets/icon/user.svg",
      func: () => {
        setTimeout(() => {
          history.push("/settings");
          history.go(0);
        }, 100);
      },
    },
    {
      title: t("Menu.items.1"),
      icon: "assets/icon/doshaTest.svg",
      accordion: true,
      items: [
        {
          title: t("Menu.items.2"),
          icon: "assets/icon/doshaTest.svg",
          func: () => {
            setTimeout(() => {
              history.push("/doshatest");
              history.go(0);
            }, 100);
          },
        },
        {
          title: t("Menu.items.3"),
          icon: "assets/icon/doshaTest.svg",
          func: () => {
            setTimeout(() => {
              history.push("/doshatest2");
              history.go(0);
            }, 100);
          },
        },
      ],
    },
    {
      title: t("Menu.items.4"),
      icon: "assets/icon/payment.svg",
      func: () => {
        setTimeout(() => {
          history.push("/payment");
          history.go(0);
        }, 100);
      },
    },
    {
      title: t("Menu.items.5"),
      icon: "assets/icon/questionnaire.svg",
      func: () => {
        setTimeout(() => {
          history.push("/questionnaire");
          history.go(0);
        }, 100);
      },
    },
    {
      title: t("Menu.items.9"),
      icon: "assets/icon/open-book.svg",
      func: () => {
        setTimeout(() => {
          history.push("/informationlisting");
          history.go(0);
        }, 100);
      },
    },
    {
      title: t("Menu.items.6"),
      icon: "assets/icon/info.svg",
      accordion: true,
      items: [
        {
          title: t("Menu.items.7"),
          icon: "assets/icon/info.svg",
          func: () => {
            setTimeout(() => {
              history.push("/terms");
              history.go(0);
            }, 100);
          },
        },
        {
          title: t("Menu.items.8"),
          icon: "assets/icon/info.svg",
          func: () => {
            setTimeout(() => {
              history.push("/rodo");
              history.go(0);
            }, 100);
          },
        },
        {
          title: t("Menu.items.11"),
          icon: "assets/icon/info.svg",
          func: () => {
            setTimeout(() => {
              history.push("/legalnotice");
              history.go(0);
            }, 100);
          },
        },
      ],
    },

    {
      title: t("Menu.items.10"),
      icon: "assets/icon/logout.svg",
      logout: true,
      func: () => {
        localStorage.removeItem("token");
        history.push("/login");
        history.go(0);
      },
    },
  ];
  useEffect(() => {
    localStorage.getItem("token") || history.push("/login");
  }, []);
  return (
    <>
      <IonMenu contentId="main-content">
        <IonContent>
          <div
            style={{
              width: "100%",
              height: `${statusBarHeight}px`,
              backgroundColor: "#000",
            }}
          />
          <div className={styles.menu}>
            <div className={styles.menuTop}>
              {goToMenu ? (
                <ReactSVG
                  className={styles.menuTopIcon}
                  src="assets/icon/left.svg"
                  onClick={() => {
                    history.goBack();
                  }}
                />
              ) : (
                <IonMenuButton>
                  <ReactSVG
                    className={styles.menuTopIcon}
                    src="assets/icon/left.svg"
                  />
                </IonMenuButton>
              )}
              <p>{t("Menu.title")}</p>
            </div>
            <div className={styles.menuUser}>
              <div className={styles.menuUserImage}>
                <img
                  className={styles.menuUserImageImg}
                  src={avatar || "assets/images/avatar.webp"}
                  alt="avatar"
                />
                <input
                  className={styles.menuUserImageInpt}
                  type="file"
                  id="avatar"
                  onChange={uploadAvatar}
                />
                <label className={styles.menuUserImageLbl} htmlFor="avatar">
                  <img src="assets/icon/pencil.svg" alt="pencil" />
                </label>
              </div>
              <p className={styles.menuUserName}>{userName}</p>
            </div>
            <div className={styles.menuItems}>
              {items.map((item, key) => (
                <div key={key}>
                  <MenuItem
                    title={item.title}
                    icon={item.icon}
                    func={item.func}
                    logout={item.logout}
                    accordion={item.accordion}
                    items={item.items}
                  />
                </div>
              ))}
            </div>
            <div className={styles.social}>
              <p className={styles.socialHeader}>{t("Menu.follow")}</p>
              <div className={styles.socialLinks}>
                <IonRouterLink
                  href="https://www.facebook.com/profile.php?id=100093596392667"
                  target="_blank"
                >
                  <img
                    className={styles.socialLinksLink}
                    src="assets/icon/facebook.svg"
                  />
                </IonRouterLink>
                <IonRouterLink
                  href="https://instagram.com/spicit.app?igshid=ZDc4ODBmNjlmNQ=="
                  target="_blank"
                >
                  <img
                    className={styles.socialLinksLink}
                    src="assets/icon/instagram.svg"
                  />
                </IonRouterLink>
              </div>
            </div>
          </div>
          <img
            className={styles.menuBg}
            src="assets/images/blue_background.svg"
          />
        </IonContent>
      </IonMenu>
      <IonPage id="main-content" className={styles.content}>
        {other ? (
          <IonHeader className={`${other && styles.headerOther}`}>
            <div
              style={{
                width: "100%",
                height: `${sizes?.top || 0}px`,
                backgroundColor: "#000",
              }}
            />
            <div
              className={`${styles.menuBar} ${other && styles.menuBarOther}`}
            >
              {!skip ? (
                <IonMenuButton>
                  <ReactSVG
                    className={`${styles.menuBarIcon} ${
                      styles.menuBarIconArrow
                    } ${other && styles.menuBarIconArrowOther}`}
                    src="assets/icon/left.svg"
                  />
                </IonMenuButton>
              ) : (
                <div></div>
              )}
              <p className={styles.mainTitle}>{title}</p>
              {skip ? (
                <a
                  href="/dashboard"
                  className={`${styles.skipBtn} ${
                    other && styles.skipBtnDisable
                  }`}
                >
                  <p>{t("skip")}</p>
                  <ReactSVG
                    className={`${styles.menuBarIcon} ${
                      styles.menuBarIconArrow
                    } ${other && styles.menuBarIconArrowOther}`}
                    src="assets/icon/left.svg"
                  />
                </a>
              ) : (
                <div></div>
              )}
            </div>
          </IonHeader>
        ) : (
          <IonHeader className={`${other && styles.headerOther}`}>
            <div
              style={{
                width: "100%",
                height: `${sizes?.top || 0}px`,
                backgroundColor: "#000",
              }}
            />
            <div
              className={`${styles.menuBar} ${other && styles.menuBarOther}`}
            >
              {hide ? (
                <div></div>
              ) : (
                <>
                  {goToMenu ? (
                    <IonMenuButton>
                      <ReactSVG
                        className={`${styles.menuBarIcon} ${
                          styles.menuBarIconArrow
                        } ${other && styles.menuBarIconArrowOther}`}
                        src="assets/icon/left.svg"
                      />
                    </IonMenuButton>
                  ) : (
                    <ReactSVG
                      className={`${styles.menuBarIcon} ${
                        styles.menuBarIconArrow
                      } ${other && styles.menuBarIconArrowOther}`}
                      src="assets/icon/left.svg"
                      onClick={() => {
                        if (link) {
                          history.push(link);
                          history.go(0);
                        } else {
                          history.goBack();
                        }
                      }}
                    />
                  )}
                </>
              )}
              <p className={styles.mainTitle}>{title}</p>
              <IonMenuButton>
                <ReactSVG
                  className={styles.menuBarIcon}
                  src="assets/icon/menu.svg"
                />
              </IonMenuButton>
            </div>
          </IonHeader>
        )}

        <IonContent>
          <div id="content-container" style={{ position: "relative" }}>
            {children}
          </div>
        </IonContent>
        <div className={styles.bottomMenu}>
          {bottomItems.map((item, index) => (
            <IonRouterLink href={item.href} key={"link" + index}>
              <div>
                <ReactSVG
                  className={`${styles.bottomMenuIcon} ${
                    item?.urls?.includes(history.location.pathname)
                      ? styles.bottomMenuIconActive
                      : ""
                  }`}
                  src={item.icon}
                />
              </div>
            </IonRouterLink>
          ))}
        </div>
        <div
          style={{
            width: "100%",
            height: `${sizes?.bottom || 0}px`,
            backgroundColor: "#000",
          }}
        />
      </IonPage>
    </>
  );
};
