import {
  IonCheckbox,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
} from "@ionic/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ErrorLabel } from "../../components/ErrorLabel";
import { Input } from "../../components/Input";
import { PrimaryButton } from "../../components/PrimaryButton";
import { Select } from "../../components/Select";
import styles from "./style.module.scss";
import { useHistory } from "react-router-dom";
import YupPassword from "yup-password";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Capacitor } from "@capacitor/core";
import { SafeArea } from "capacitor-plugin-safe-area";
import { Loader } from "../../components/Loader";
YupPassword(Yup);

export const RegisterPage = () => {
  const [loading, setLoading] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const { t } = useTranslation();
  const genders = [
    {
      value: "man",
      label: t("RegisterPage.genders.man"),
    },
    {
      value: "woman",
      label: t("RegisterPage.genders.woman"),
    },
    {
      value: "other",
      label: t("RegisterPage.genders.other"),
    },
  ];
  const languages = [
    {
      value: "en",
      label: t("SettingsPage.languages.english"),
    },
    {
      value: "de",
      label: t("SettingsPage.languages.german"),
    },
  ];

  const history = useHistory();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("RegisterPage.schema.email"))
      .required(t("RegisterPage.schema.required")),
    password: Yup.string()
      .min(8, t("RegisterPage.schema.password.min"))
      .minLowercase(1, t("RegisterPage.schema.password.lowercase"))
      .minUppercase(1, t("RegisterPage.schema.password.uppercase"))
      .minNumbers(1, t("RegisterPage.schema.password.number"))
      .minSymbols(1, t("RegisterPage.schema.password.special"))
      .required(t("RegisterPage.schema.required")),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        t("RegisterPage.schema.confirmPassword")
      )
      .required(t("RegisterPage.schema.required")),
    name: Yup.string().required(t("RegisterPage.schema.required")),
    lastname: Yup.string().required(t("RegisterPage.schema.required")),
    gender: Yup.string().required(t("RegisterPage.schema.required")),
    language: Yup.string().required(t("RegisterPage.schema.required")),
    terms: Yup.boolean().oneOf([true], t("RegisterPage.schema.required")),
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    values,
    setValues,
  } = useFormik({
    validationSchema: LoginSchema,
    initialValues: {
      name: "",
      lastname: "",
      email: "",
      gender: "",
      language: "",
      password: "",
      confirmPassword: "",
      terms: false,
    },
    onSubmit: async (values: any) => {
      setLoading(true);
      localStorage.removeItem("loginForm");
      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("lastName", values.lastname);
      formdata.append("email", values.email);
      formdata.append("gender", values.gender);
      formdata.append("password", values.password);
      formdata.append("confirmPassword", values.confirmPassword);
      formdata.append("language", values.language);

      const requestOptions: any = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      if (
        process.env.REACT_APP_API_URL &&
        process.env.REACT_APP_USER_REGISTER
      ) {
        await fetch(
          process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_REGISTER,
          requestOptions
        ).then(async (response) => {
          if (response.status === 200) {
            const res = await response.json();
            localStorage.setItem("email", values.email);
            localStorage.setItem("newUser", "true");
            history.push("/successregister");
          } else {
            setInvalidCredentials(true);
          }
        });
      }
      setLoading(false);
    },
  });
  const [statusBarHeight, setStatusBarHeight] = useState<any>(0);
  useEffect(() => {
    const ls = localStorage.getItem("loginForm");
    if (ls) setValues(JSON.parse(ls));

    if (Capacitor.getPlatform() === "ios") {
      SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
        setStatusBarHeight(statusBarHeight);
      });
    }
  }, []);

  return (
    <IonPage id="main-content">
      <div
        style={{
          width: "100%",
          height: `${statusBarHeight}px`,
          backgroundColor: "#000",
        }}
      />
      <IonContent>
        <div className={styles.wrapper}>
          <div className={styles.registerContainer}>
            <div className={styles.registerTop}>
              <a className={styles.registerTop__link} href="/login">
                <img
                  className={styles.registerTop__left}
                  src="assets/icon/left_1.svg"
                  alt=""
                />
              </a>
              <img
                className={styles.registerTop__img}
                src="assets/logos/redy-to-spice.svg"
                alt=""
              />
            </div>
            <form onSubmit={handleSubmit} className={styles.form}>
              <Input
                type="text"
                name="name"
                placeholder={t("RegisterPage.form.name")}
                onChange={handleChange("name")}
                onBlur={handleBlur("name")}
                defaultValue={values.name}
              />
              <ErrorLabel error={errors.name} touched={touched.name} />
              <Input
                type="text"
                name="lastname"
                placeholder={t("RegisterPage.form.lastname")}
                onChange={handleChange("lastname")}
                onBlur={handleBlur("lastname")}
                defaultValue={values.lastname}
              />
              <ErrorLabel error={errors.lastname} touched={touched.lastname} />
              <Input
                type="email"
                name="email"
                placeholder={t("RegisterPage.form.email")}
                onChange={handleChange("email")}
                onBlur={handleBlur("email")}
                defaultValue={values.email}
              />
              <ErrorLabel error={errors.email} touched={touched.email} />
              <Select
                name="gender"
                placeholder={t("RegisterPage.form.gender")}
                onChange={handleChange("gender")}
                onBlur={handleBlur("gender")}
                data={genders}
                defaultValue={values.gender}
              />
              <ErrorLabel error={errors.gender} touched={touched.gender} />
              <Select
                name="language"
                placeholder={t("RegisterPage.form.language")}
                onChange={handleChange("language")}
                onBlur={handleBlur("language")}
                data={languages}
                defaultValue={values.language}
              />
              <ErrorLabel error={errors.language} touched={touched.language} />
              <Input
                name="password"
                type="password"
                placeholder={t("RegisterPage.form.password")}
                onChange={handleChange("password")}
                onBlur={handleBlur("password")}
              />
              <ErrorLabel error={errors.password} touched={touched.password} />
              <Input
                name="confirmPassword"
                type="password"
                placeholder={t("RegisterPage.form.confirmPassword")}
                onChange={handleChange("confirmPassword")}
                onBlur={handleBlur("confirmPassword")}
              />
              <ErrorLabel
                error={errors.confirmPassword}
                touched={touched.confirmPassword}
              />
              <div
                style={{
                  padding: "16px 20px",
                  display: "grid",
                  alignItems: "center",
                  gridTemplateColumns: "18px 1fr",
                  gap: "16px",
                  borderBottom: "1px solid #d2d2d2",
                  fontSize: "18px",
                }}
              >
                <input
                  type={"checkbox"}
                  name="terms"
                  id="terms"
                  onChange={handleChange("terms")}
                  onBlur={handleBlur("terms")}
                />
                <label htmlFor="terms">
                  {t("RegisterPage.terms1")}{" "}
                  <span
                    onClick={(e: any) => {
                      e.preventDefault();
                      const res: any = values;
                      res.password = "";
                      res.confirmPassword = "";
                      res.terms = false;
                      localStorage.setItem("loginForm", JSON.stringify(res));
                      history.push("/terms");
                      history.go(0);
                    }}
                    style={{
                      color: "#078179",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {t("RegisterPage.terms2")}{" "}
                  </span>{" "}
                  &{" "}
                  <a
                    onClick={(e: any) => {
                      e.preventDefault();
                      const res: any = values;
                      res.password = "";
                      res.confirmPassword = "";
                      res.terms = false;
                      localStorage.setItem("loginForm", JSON.stringify(res));
                      history.push("/rodo");
                      history.go(0);
                    }}
                    style={{
                      color: "#078179",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {t("RegisterPage.rodo2")}
                  </a>
                </label>
              </div>
              <ErrorLabel error={errors.terms} touched={touched.terms} />
              <ErrorLabel
                error={t("RegisterPage.form.error")}
                touched={invalidCredentials}
              />
              <PrimaryButton text={t("RegisterPage.form.button")} />
            </form>
            <div className={styles.form__trial}>
              {t("RegisterPage.form.trial")}
            </div>
            <div className={styles.background}></div>
          </div>
          <img
            className={styles.register__img}
            src="assets/backgrounds/blue_background.svg"
            alt=""
          />

          {loading && <Loader />}
        </div>
      </IonContent>
    </IonPage>
  );
};
