import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { ReactSVG } from "react-svg";
import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";
import { Loader } from "../../components/Loader";

export const HealitListingPage = () => {
  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const [dosha, setDosha] = useState<any>("generic");
  const [bodyType, setBodyType] = useState<any>(query.get("bodyType"));
  const [search, setSearch] = useState<any>(query.get("search") || "");
  const [page, setPage] = useState(1);
  const [load, setLoad] = useState(false);

  const { t } = useTranslation();
  const [data, setData] = useState<any>([]);
  const getPosts = async () => {
    setLoad(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const query = search
      ? `?page=${page}&search=${search}&type=healit`
      : `?page=${page}&bodyType=${bodyType}&type=healit`;
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_CMS_POSTS) {
      await fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_CMS_POSTS + query,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setData([...data, ...res.data.items]);
        }
      });
    }
    setLoad(false);
  };
  useEffect(() => {
    if (dosha && (bodyType || search)) getPosts();
  }, [dosha, bodyType]);
  useEffect(() => {
    if (dosha && (bodyType || search)) getPosts();
  }, [page]);
  const [inpt, setInpt] = useState<any>("");

  return (
    <Menu title={search ? search : t(`HealitListingPage.${bodyType}`)}>
      <div className={styles.healit}>
        <div
          className={styles.healitSearch}
          style={{
            backgroundColor:
              dosha === "kapha"
                ? "#e3fff8"
                : dosha === "pitta"
                ? "#ffe8e9"
                : dosha === "vata"
                ? "#f3f9ff"
                : "transparent",
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              history.push(`/healitlisting?dosha=${dosha}&search=${inpt}`);
              history.go(0);
            }}
          >
            <input
              type="text"
              placeholder={t("HealitListingPage.search")}
              onChange={(e) => setInpt(e.target.value)}
            />
          </form>
          <img
            src="/assets/icon/search.svg"
            alt="search"
            onClick={() => {
              history.push(`/healitlisting?dosha=${dosha}&search=${inpt}`);
              history.go(0);
            }}
          />
        </div>
        <div className={styles.healitList}>
          {data.length
            ? data.map((item: any) => (
                <a
                  href={`/healititem?id=${item.id}`}
                  key={item.id}
                  className={styles.healitListItem}
                >
                  <p className={styles.healitListItemTitle}>{item.title}</p>
                  <ReactSVG
                    className={styles.healitListItemIcon}
                    src={"/assets/icon/right.svg"}
                  />
                </a>
              ))
            : null}
        </div>
        {!data.length && (
          <p style={{ textAlign: "center", marginBottom: 32 }}>
            {t("noSearchResults")}
          </p>
        )}
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            setPage(page + 1);
            setTimeout(() => ev.target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        {load && <Loader />}
      </div>
    </Menu>
  );
};
