import { useEffect } from "react";
import { ReactSVG } from "react-svg";
import styles from "./style.module.scss";

const data = [
  {
    name: "Vata",
    img: "/assets/icon/doshaVata.svg",
  },
  {
    name: "Pitta",
    img: "/assets/icon/doshaPitta.svg",
  },
  {
    name: "Kapha",
    img: "/assets/icon/doshaKapha.svg",
  },
];

export const DoshaCheck = ({ onChange, onBlur, defaultValue }: any) => {
  useEffect(() => {
    if (defaultValue && defaultValue !== "Generic") {
      const input = document.querySelector(
        `input[value="${defaultValue}"]`
      ) as HTMLInputElement;
      if (input) {
        input.checked = true;
      }
    }
  }, [defaultValue]);
  return (
    <div className={styles.items}>
      {data.map((item, key) => (
        <>
          <input
            key={"itemInput" + key}
            type="radio"
            id={"item" + key}
            onChange={onChange}
            onBlur={onBlur}
            name="dosha"
            value={item.name.toLowerCase()}
          ></input>
          <label
            className={styles.itemsItem}
            key={"itemLabel" + key}
            htmlFor={"item" + key}
          >
            <ReactSVG src={item.img} />
            <p>{item.name}</p>
          </label>
        </>
      ))}
    </div>
  );
};
