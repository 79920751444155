import { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import styles from "./style.module.scss";

export const SearchMany = ({ arrIngredients, setArrIngredients }) => {
  const history = useHistory();
  const [openSearch, setOpenSearch] = useState(false);
  const [value, setValue] = useState("");

  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [numItemsPerPage, setNumItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();

  const { t } = useTranslation();
  const getData = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_RECIPES) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES +
          `?page=${page}&phrase=${value}`,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setTotalCount(res.data.totalCount);
          setNumItemsPerPage(res.data.numItemsPerPage);
          setData([...res.data.items.ingredients]);
        }
      });
    }
  };

  useEffect(() => {
    if (value.length > 2) getData();
  }, [value]);

  const arrIngredientsHandler = (item) => {
    if (arrIngredients.some((ingredient) => ingredient.id === item.id)) {
      return;
    }
    setArrIngredients([...arrIngredients, item]);
    setValue("");
  };
  return (
    <>
      <div className={styles.Search}>
        <DebounceInput
          placeholder={t("RecipesListingIngredientsPage.search")}
          className={styles.SearchInput}
          minLength={2}
          debounceTimeout={300}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          onFocus={() => setOpenSearch(true)}
          onBlur={() => setTimeout(() => setOpenSearch(false), 500)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              history.push(
                `/recipeslisting?phrase=${value}&&categoryName=${value}`
              );
              history.go(0);
            }
          }}
        />
        {openSearch && value.length > 2 && (
          <ul>
            {data.map((d: any, key: number) => (
              <div
                key={key}
                onClick={() =>
                  arrIngredientsHandler({ id: d.id, name: d.name })
                }
              >
                <li>{d.name}</li>
              </div>
            ))}
          </ul>
        )}
        <img
          className={styles.SearchIcon}
          src="/assets/icon/search.svg"
          alt="search"
          onClick={() => {
            history.push(
              `/recipeslisting?phrase=${value}&&categoryName=${value}`
            );
            history.go(0);
          }}
        />
      </div>
      <div className={styles.IngrList}>
        {arrIngredients.map((item) => (
          <div key={item.id} className={styles.IngrListItem}>
            <p className={styles.IngrListItemLabel}>{item.name}</p>
            <button
              className={styles.IngrListItemBtn}
              onClick={() =>
                setArrIngredients(
                  arrIngredients.filter(
                    (ingredient) => ingredient.id !== item.id
                  )
                )
              }
            ></button>
          </div>
        ))}
      </div>
    </>
  );
};
