import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { ReactSVG } from "react-svg";
import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";

export const InformationListingPage = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [data, setData] = useState<any>([]);
  const getPosts = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_CMS_POSTS) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_CMS_POSTS +
          `?type=information&page=${page}`,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setData([...data, ...res.data.items]);
        }
      });
    }
  };

  useEffect(() => {
    getPosts();
  }, [page]);

  return (
    <Menu title={t("InformationListingPage.title")} goToMenu>
      <div className={styles.healit}>
        <div className={styles.healitList}>
          {data.map((item: any) => (
            <a
              href={`/information?id=${item.id}`}
              key={item.id}
              className={styles.healitListItem}
            >
              <p className={styles.healitListItemTitle}>{item.title}</p>
              <ReactSVG
                className={styles.healitListItemIcon}
                src={"/assets/icon/right.svg"}
              />
            </a>
          ))}
        </div>
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            setPage(page + 1);
            setTimeout(() => ev.target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </div>
    </Menu>
  );
};
