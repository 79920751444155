import { useEffect, useState } from "react";
import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { BottomBlob } from "../../components/BottomBlob";
import { Search } from "../../components/Search";
import { Filters } from "../../components/Filters";
import { Loader } from "../../components/Loader";

export const RecipesPage = () => {
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState<any>([]);
  const getData = async () => {
    setLoad(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_RECIPES_CATEGORIES
    ) {
      await fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES_CATEGORIES,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          res.data.sort((a, b) =>
            a.isRootCategory === true ? -1 : b.isRootCategory === true ? 1 : 0
          );
          setData(res.data);
        }
      });
    }
    setLoad(false);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Menu title={t("RecipesPage.title")}>
      <div className={styles.recipes}>
        <Search />
        <div className={styles.recipesTiles}>
          {data.map((d: any, key: number) => (
            <a
              key={key}
              href={`/recipeslisting?categoryId=${d.id}&&categoryName=${d.names}`}
            >
              <div
                className={styles.recipesTilesTile}
                style={{ backgroundImage: `url(${d.media})` }}
              >
                <h2>{d.names}</h2>
              </div>
            </a>
          ))}
        </div>
        <BottomBlob />
        {load && <Loader />}
      </div>
    </Menu>
  );
};
