//"2018-05-01" to "2018-07-01"
export const datesBetween = (start: string, end: string) => {
  let getDaysArray = function (s, e) {
    for (
      var a: any = [], d = new Date(s);
      d <= new Date(e);
      d.setDate(d.getDate() + 1)
    ) {
      a.push(new Date(d));
    }
    return a;
  };
  let daylist = getDaysArray(
    new Date(start.split("-").reverse().join("-")),
    new Date(end.split("-").reverse().join("-"))
  );
  return daylist.map((v: any) =>
    v.toISOString().slice(0, 10).split("-").reverse().join("-")
  );
};
