import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";

export const InformationPage = () => {
  const { t } = useTranslation();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const [id, setId] = useState<any>(query.get("id"));
  const [description, setDescription] = useState<any>("");
  const getPosts = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_CMS_POSTS) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_CMS_POSTS +
          `?id=${id}`,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setDescription(res.data.items[0]);
        }
      });
    }
  };
  useEffect(() => {
    getPosts();
  }, []);
  return (
    <Menu title={description.title}>
      <div className={styles.cms}>
        <div
          className={styles.cmsContent}
          dangerouslySetInnerHTML={{ __html: description.content }}
        />
      </div>
    </Menu>
  );
};
