import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { Input } from "../../components/Input";
import { ErrorLabel } from "../../components/ErrorLabel";
import { Select } from "../../components/Select";
import { PrimaryButton } from "../../components/PrimaryButton";
import YupPassword from "yup-password";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { IonButton, useIonActionSheet } from "@ionic/react";
import type { OverlayEventDetail } from "@ionic/core";
import {
  IonDatetime,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPopover,
  IonToggle,
} from "@ionic/react";
import { DoshaCheck } from "../../components/DoshaCheck";
import { useHistory } from "react-router";
import { LocalNotifications } from "@capacitor/local-notifications";
import { FCM } from "@capacitor-community/fcm";

YupPassword(Yup);

export const SettingsPage = () => {
  const { t, i18n } = useTranslation();
  const genders = [
    {
      value: "man",
      label: t("SettingsPage.genders.man"),
    },
    {
      value: "woman",
      label: t("SettingsPage.genders.woman"),
    },
    {
      value: "other",
      label: t("SettingsPage.genders.other"),
    },
  ];
  const weekdays = [
    {
      value: "monday",
      label: t("SettingsPage.weekdays.monday"),
    },
    {
      value: "tuesday",
      label: t("SettingsPage.weekdays.tuesday"),
    },
    {
      value: "wednesday",
      label: t("SettingsPage.weekdays.wednesday"),
    },
    {
      value: "thursday",
      label: t("SettingsPage.weekdays.thursday"),
    },
    {
      value: "friday",
      label: t("SettingsPage.weekdays.friday"),
    },
    {
      value: "saturday",
      label: t("SettingsPage.weekdays.saturday"),
    },
    {
      value: "sunday",
      label: t("SettingsPage.weekdays.sunday"),
    },
  ];
  const languages = [
    {
      value: "en",
      label: t("SettingsPage.languages.english"),
    },
    {
      value: "de",
      label: t("SettingsPage.languages.german"),
    },
  ];
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const [defaultDiets, setDefaultDiets] = useState<any>();
  const [defaultAllergies, setDefaultAllergies] = useState();
  const [defaultCategories, setDefaultCategories] = useState();
  const [present] = useIonActionSheet();
  const [result, setResult] = useState<OverlayEventDetail>();

  //--- POPOVER
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  //---

  //---  GET DIETS
  const [diets, setDiets] = useState<any>([]);
  const getDiets = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_USER_DIETS) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_DIETS,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          const arr: any = [
            {
              value: "none",
              label: "None",
            },
          ];
          res.data.forEach((item: any) => {
            arr.push({
              value: item.id,
              label: item.name,
            });
          });
          setDiets(arr);
        }
      });
    }
  };
  //---
  //---  GET USER DATA
  const [userData, setUserData] = useState<any>([]);
  const getUserData = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_USER_READ) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_READ,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setUserData(res.data);
          setFieldValue("name", res.data.name);
          setFieldValue("lastname", res.data.lastName);
          setFieldValue("email", res.data.email);
          setFieldValue("peoplecook", res.data.servings);
          setFieldValue("dosha", res.data.dosha);
          if (res.data.isNewsletter) {
            setIsChecked(true);
          }

          let x;
          try {
            x = res.data.attributes
              .filter((item: any) => item.categoryName == "Diets")
              .map((item: any) => item.attributeId);
          } catch (e) {
            x = "none";
          }
          setDefaultDiets(x);

          if (res.data.allergies) {
            setDefaultAllergies(
              res.data.attributes
                .filter((item: any) => item.categoryName == "Allergies")
                .map((item: any) => item.attributeId)
            );
          }
          setDefaultCategories(
            res.data.recipeCategory ? res.data.recipeCategory : "null"
          );
        }
      });
    }
  };
  //---
  //---  GET ALLERGIES
  const [allergies, setAllergies] = useState<any>([]);
  const getAllergies = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_USER_ALLERGIES) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_ALLERGIES,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          const arr: any = [];
          res.data.forEach((item: any) => {
            arr.push({
              value: item.id,
              label: item.name,
            });
          });
          setAllergies(arr);
        }
      });
    }
  };
  //---
  //---  GET CATEGORIES
  const [categories, setCategories] = useState<any>([]);
  const getCategories = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_RECIPES_CATEGORIES
    ) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES_CATEGORIES,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          const arr: any = [];

          res.data
            .filter((item: any) => item.usedInWeeklyPlan === true)
            .forEach((item: any) => {
              arr.push({
                value: item.id,
                label: item.names,
              });
            });
          setCategories(arr);
        }
      });
    }
  };
  //---
  //---  DELETE ACCOUNT
  const deleteAccount = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_USER_DELETE) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_DELETE,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          localStorage.removeItem("token");
          history.push("/login");
        }
      });
    }
  };
  useEffect(() => {
    if (result?.data?.action === "delete") deleteAccount();
    setResult({ data: { action: "" } });
  }, [result?.data?.action]);
  //---
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("SettingsPage.schema.email"))
      .required(t("SettingsPage.schema.required")),
    password: Yup.string()
      .min(8, t("SettingsPage.schema.password.min"))
      .minLowercase(1, t("SettingsPage.schema.password.lowercase"))
      .minUppercase(1, t("SettingsPage.schema.password.uppercase"))
      .minNumbers(1, t("SettingsPage.schema.password.number"))
      .minSymbols(1, t("SettingsPage.schema.password.special")),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("SettingsPage.schema.confirmPassword")
    ),
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
  } = useFormik({
    validationSchema: LoginSchema,
    initialValues: {
      email: "",
      name: "",
      lastname: "",
      password: "",
      confirmPassword: "",
      gender: "",
      language: "",
      // diet: "",
      // allergies: "",
      // categories: "",
      // peoplecook: "",
      // weekday: "",
      // time: "",
      // dosha: "",
    },
    onSubmit: async (values: any) => {
      const myHeaders = new Headers();
      const token = localStorage.getItem("token");
      myHeaders.append("Authorization", `Bearer ${token}`);
      const formdata = new FormData();
      formdata.append("email", values.email);
      formdata.append("name", values.name);
      formdata.append("lastName", values.lastname);
      formdata.append("password", values.password);
      formdata.append("confirmPassword", values.confirmPassword);
      formdata.append("gender", values.gender);
      formdata.append("language", values.language);
      formdata.append("isNewsletter", JSON.stringify(isChecked));
      i18n.changeLanguage(values.language);
      // formdata.append("dosha", values.dosha);
      // formdata.append("servings", values.peoplecook);
      // formdata.append(
      //   "recipeCategoryId",
      //   values.categories === "null" ? "" : values.categories
      // );
      // if (values.weekday && values.time && isChecked) {
      //   formdata.append(
      //     "reminder",
      //     values.weekday + "-" + values.time.split("T")[1].split("+")[0]
      //   );
      // } else {
      //   formdata.append("reminder", "");
      // }

      // formdata.append("diet[]", values.diet === "none" ? "" : values.diet);

      // for (const property in values.allergies) {
      //   formdata.append("allergies[]", values.allergies[property]);
      // }

      const requestOptions: any = {
        method: "POST",
        body: formdata,
        redirect: "follow",
        headers: myHeaders,
      };
      if (process.env.REACT_APP_API_URL && process.env.REACT_APP_USER_UPDATE) {
        fetch(
          process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_UPDATE,
          requestOptions
        ).then(async (response) => {
          if (response.status === 200) {
            const res = await response.json();
            i18n.changeLanguage(values.language);
            await FCM.unsubscribeFrom({ topic: "en" }).catch((err) =>
              console.log(err)
            );
            await FCM.unsubscribeFrom({ topic: "de" }).catch((err) =>
              console.log(err)
            );
            await FCM.subscribeTo({ topic: values.language }).catch((err) =>
              console.log(err)
            );

            if (values.weekday && values.time && isChecked) {
              const days = [
                "sunday",
                "monday",
                "tuesday",
                "wednesday",
                "thursday",
                "friday",
                "saturday",
              ];
              const weekday = days.indexOf(values.weekday) + 1;
              const date = new Date(values.time);
              const hours = date.getHours();
              const minutes = date.getMinutes();
              localNotification(weekday, hours, minutes, 0);
            } else {
              localNotificationCancel();
            }
            history.push("/dashboard");
          }
        });
      }
    },
  });
  useEffect(() => {
    getCategories();
    // getAllergies();
    getDiets();
    getUserData();
  }, []);
  const timeRef = useRef<any>(null);

  //---
  const localNotification = async (weekday, hour, minute, second) => {
    if ((await LocalNotifications.requestPermissions()).display === "granted") {
      LocalNotifications.schedule({
        notifications: [
          {
            title: "Spicit",
            body: t("Notifications.localPush"),
            id: 123,
            schedule: {
              on: {
                weekday: weekday,
                hour: hour,
                minute: minute,
                second: second,
              },
            },
          },
        ],
      });
    }
  };
  const localNotificationCancel = async () => {
    if ((await LocalNotifications.requestPermissions()).display === "granted") {
      LocalNotifications.cancel({
        notifications: [
          {
            id: 123,
          },
        ],
      });
    }
  };
  //---
  return (
    <Menu title={t("SettingsPage.title")} other goToMenu>
      <form onSubmit={handleSubmit} className={styles.settings}>
        <Input
          settings
          type="text"
          name="name"
          placeholder={t("SettingsPage.form.name")}
          onChange={handleChange("name")}
          onBlur={handleBlur("name")}
          defaultValue={userData.name}
        />
        <ErrorLabel error={errors.name} touched={touched.name} />
        <Input
          settings
          type="text"
          name="lastname"
          placeholder={t("SettingsPage.form.lastname")}
          onChange={handleChange("lastname")}
          onBlur={handleBlur("lastname")}
          defaultValue={userData.lastName}
        />
        <ErrorLabel error={errors.lastname} touched={touched.lastname} />
        <Input
          settings
          type="email"
          name="email"
          placeholder={t("SettingsPage.form.email")}
          onChange={handleChange("email")}
          onBlur={handleBlur("email")}
          defaultValue={userData.email}
        />
        <ErrorLabel error={errors.email} touched={touched.email} />
        <Input
          name="password"
          type="password"
          placeholder={t("SettingsPage.form.password")}
          onChange={handleChange("password")}
          onBlur={handleBlur("password")}
        />
        <ErrorLabel error={errors.password} touched={touched.password} />
        <Input
          name="confirmPassword"
          type="password"
          placeholder={t("SettingsPage.form.confirmPassword")}
          onChange={handleChange("confirmPassword")}
          onBlur={handleBlur("confirmPassword")}
        />
        <ErrorLabel
          error={errors.confirmPassword}
          touched={touched.confirmPassword}
        />
        <Select
          onlyLabel
          name="language"
          placeholder={t("SettingsPage.form.language")}
          onChange={handleChange("language")}
          onBlur={handleBlur("language")}
          defaultValue={userData.language}
          data={languages}
        />
        <ErrorLabel error={errors.language} touched={touched.language} />
        <Select
          onlyLabel
          name="gender"
          placeholder={t("SettingsPage.form.gender")}
          onChange={handleChange("gender")}
          onBlur={handleBlur("gender")}
          data={genders}
          defaultValue={userData.gender}
        />
        <ErrorLabel error={errors.gender} touched={touched.gender} />
        <div className={styles.reminder}>
          <p>{t("SettingsPage.form.newsletter")}</p>
          <IonToggle
            slot="end"
            checked={isChecked}
            onIonChange={() => setIsChecked(!isChecked)}
          />
        </div>
        {/* <Select
          // multiple
          onlyLabel
          name="diet"
          placeholder={t("SettingsPage.form.diet")}
          onChange={handleChange("diet")}
          onBlur={handleBlur("diet")}
          data={diets}
          defaultValue={defaultDiets}
        />

        <ErrorLabel error={errors.diet} touched={touched.diet} />
        <Select
          onlyLabel
          name="categories"
          placeholder={t("SettingsPage.form.categories")}
          onChange={handleChange("categories")}
          onBlur={handleBlur("categories")}
          data={categories}
          defaultValue={defaultCategories}
        />
        <ErrorLabel error={errors.categories} touched={touched.categories} /> */}

        {/* <div className={styles.settingsCook}>
          <div className={styles.settingsCookLabel}>
            {t("SettingsPage.form.meals")}
          </div>
          <Input
            type="number"
            min={0}
            step={1}
            name="peoplecook"
            placeholder={"0"}
            onChange={handleChange("peoplecook")}
            onBlur={handleBlur("peoplecook")}
            defaultValue={userData.servings}
          />
        </div>
        <ErrorLabel error={errors.peoplecook} touched={touched.peoplecook} />
        <div className={styles.reminder}>
          <p>{t("SettingsPage.form.reminder")}</p>
          <IonToggle
            slot="end"
            checked={isChecked}
            onIonChange={() => setIsChecked(!isChecked)}
          />
        </div> */}
        {/* {isChecked && (
          <div className={styles.settingsDatePicker}>
            <input
              className={styles.settingsTimePicker}
              placeholder={t("SettingsPage.form.time")}
              value={values?.time?.split("T")[1]?.split("+")[0]}
              onClick={openPopover}
            ></input>
            <Select
              name="weekday"
              placeholder={t("SettingsPage.form.weekday")}
              onChange={handleChange("weekday")}
              onBlur={handleBlur("weekday")}
              data={weekdays}
              defaultValue={userData?.reminder?.split("-")[0]}
            />
            <IonPopover
              ref={popover}
              isOpen={popoverOpen}
              onDidDismiss={() => setPopoverOpen(false)}
            >
              <IonDatetime
                ref={timeRef}
                name="time"
                presentation="time"
                onIonChange={handleChange}
                onBlur={handleBlur}
              ></IonDatetime>
            </IonPopover>
          </div>
        )} */}
        {/* <div style={{ margin: 20 }}></div>
        <DoshaCheck
          onChange={handleChange("dosha")}
          onBlur={handleBlur("dosha")}
          defaultValue={userData.dosha}
        /> */}
        <PrimaryButton text={t("SettingsPage.form.button")} color="#088179" />
        <p
          className={styles.settingsDeleteBtn}
          onClick={() =>
            present({
              header: t("SettingsPage.deleteAccount.title"),
              buttons: [
                {
                  text: t("SettingsPage.deleteAccount.yes"),
                  role: "destructive",
                  data: {
                    action: "delete",
                  },
                },
                {
                  text: t("SettingsPage.deleteAccount.no"),
                  role: "cancel",
                  data: {
                    action: "cancel",
                  },
                },
              ],
              onDidDismiss: ({ detail }) => setResult(detail),
            })
          }
        >
          {t("SettingsPage.deleteAccount.button")}
        </p>
      </form>
    </Menu>
  );
};
