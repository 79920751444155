import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { ReactSVG } from "react-svg";
import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";

export const DiscoverItemPage = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const [id, setId] = useState<any>(query.get("id"));

  const { t } = useTranslation();
  const [data, setData] = useState<any>([]);
  const getPosts = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_BLOG_READ) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_BLOG_READ +
          `?id=${id}`,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setData(res.data);
        }
      });
    }
  };
  useEffect(() => {
    if (id) getPosts();
  }, [id]);

  return (
    <Menu title={data.title}>
      <div className={styles.healitMeta}>
        <img src={data.media} alt={data.title} />
        <p>{data?.date?.split(" ")[0]}</p>
        <h1>{data.title}</h1>
      </div>
      <div
        className={styles.healit}
        dangerouslySetInnerHTML={{ __html: data.content }}
      />
    </Menu>
  );
};
