import { IonContent, IonPage } from "@ionic/react";
import { ErrorLabel } from "../../components/ErrorLabel";
import { Input } from "../../components/Input";
import { PrimaryButton } from "../../components/PrimaryButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./style.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export const NewPasswordPage = () => {
  const [success, setSuccess] = useState(false);
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();

  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t("NewPasswordPage.schema.password.min"))
      .minLowercase(1, t("NewPasswordPage.schema.password.lowercase"))
      .minUppercase(1, t("NewPasswordPage.schema.password.uppercase"))
      .minNumbers(1, t("NewPasswordPage.schema.password.number"))
      .minSymbols(1, t("NewPasswordPage.schema.password.special"))
      .required(t("NewPasswordPage.schema.required")),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        t("NewPasswordPage.schema.confirmPassword")
      )
      .required(t("NewPasswordPage.schema.required")),
  });

  const { handleChange, handleSubmit, handleBlur, errors, touched, values } =
    useFormik({
      validationSchema: LoginSchema,
      initialValues: {
        password: "",
        confirmPassword: "",
      },
      onSubmit: async (values: any) => {
        const formdata = new FormData();
        formdata.append("id", query.get("id")?.toString() || "");
        formdata.append("password", values.password);
        formdata.append("confirmPassword", values.confirmPassword);

        const requestOptions: any = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
        if (
          process.env.REACT_APP_API_URL &&
          process.env.REACT_APP_USER_NEW_PASSWORD
        ) {
          fetch(
            process.env.REACT_APP_API_URL +
              process.env.REACT_APP_USER_NEW_PASSWORD,
            requestOptions
          ).then(async (response) => {
            if (response.status === 200) {
              setSuccess(true);
            }
          });
        }
      },
    });
  return (
    <IonPage id="main-content">
      <IonContent>
        <div className={styles.wrapper}>
          <div className={styles.login}>
            <img
              className={styles.loginLogo}
              src="/assets/images/logo.png"
              alt="logo"
            />
            <p className={styles.loginSubtitle}>
              {t("NewPasswordPage.subtitle")}
            </p>
            <img
              className={styles.loginImage}
              src="/assets/images/head.webp"
              alt="img"
            />
            {success ? (
              <>
                <h1>{t("NewPasswordPage.success")}</h1>
                <a href="/login" style={{ textDecoration: "none" }}>
                  <PrimaryButton text={t("NewPasswordPage.login")} />
                </a>
              </>
            ) : (
              <form onSubmit={handleSubmit} className={styles.loginForm}>
                <Input
                  login
                  name="password"
                  type="password"
                  placeholder={t("NewPasswordPage.form.password")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorLabel
                  error={errors.password}
                  touched={touched.password}
                />
                <Input
                  login
                  name="confirmPassword"
                  type="password"
                  placeholder={t("NewPasswordPage.form.confirmPassword")}
                  onChange={handleChange("confirmPassword")}
                  onBlur={handleBlur("confirmPassword")}
                />
                <ErrorLabel
                  error={errors.confirmPassword}
                  touched={touched.confirmPassword}
                />
                <PrimaryButton text={t("NewPasswordPage.form.button")} />
              </form>
            )}
          </div>
          <img
            className={styles.loginBlob}
            src="assets/backgrounds/blue_background.svg"
            alt=""
          />
        </div>
      </IonContent>
    </IonPage>
  );
};
