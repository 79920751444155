import { useEffect, useState } from "react";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { BottomBlob } from "../../components/BottomBlob";
import { ReactSVG } from "react-svg";
import { useLocation } from "react-router-dom";
import { Search } from "../../components/Search";
import { like } from "../../helpers/like";
import { Filters } from "../../components/Filters";
import { Loader } from "../../components/Loader";

export const RecipesListingPage = () => {
  const [filter, setFilter] = useState("");
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();

  const { t } = useTranslation();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [numItemsPerPage, setNumItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const getData = async (reset?: any) => {
    setLoad(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_RECIPES &&
      process.env.REACT_APP_RECIPES_INGREDIENT
    ) {
      let address: any = "";
      if (filter) {
        address =
          process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES +
          `?page=${reset ? 1 : page}${filter}`;
      } else if (query.get("categoryId") && filter !== null) {
        address =
          process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES +
          `?page=${reset ? 1 : page}${
            filter ? filter : "&categoryId=" + query.get("categoryId")
          }`;
      } else if (query.get("id")) {
        address =
          process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES_INGREDIENT +
          `?page=${reset ? 1 : page}${
            query.get("id") ? "&id=" + query.get("id") : ""
          }`;
      } else if (query.get("phrase")) {
        address =
          process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES +
          `?page=${reset ? 1 : page}${
            query.get("phrase") ? "&phrase=" + query.get("phrase") : ""
          }${filter}`;
      } else {
        address =
          process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES +
          `?page=${reset ? 1 : page}`;
      }

      await fetch(address, requestOptions).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setTotalCount(res.data.totalCount);
          setNumItemsPerPage(res.data.numItemsPerPage);
          const prev = reset ? [] : [...data];
          if (res.data.items.recipes) {
            setData([...prev, ...res.data.items.recipes]);
          } else {
            setData([...prev, ...res.data.items]);
          }
        }
      });
    }
    setLoad(false);
  };

  useEffect(() => {
    getData();
  }, [page]);
  const generateItems = () => {
    if (page * numItemsPerPage >= totalCount) return;
    setPage(page + 1);
  };
  useEffect(() => {
    console.log(filter);

    getData(true);
  }, [filter]);

  return (
    <Menu
      title={
        query.get("categoryName") && filter !== null
          ? filter
            ? t("RecipesListingPage.title")
            : query.get("categoryName")
          : t("RecipesListingPage.title")
      }
    >
      {!query.get("id") && (
        <Filters
          filter={filter}
          setFilter={setFilter}
          initial={query.get("categoryId")}
        />
      )}
      <div className={styles.recipes}>
        <Search />
        <div className={styles.recipesTiles}>
          {data.length
            ? data.map((d: any, key: number) => (
                <div key={key} className={styles.recipesTilesTileWrap}>
                  <div
                    className={styles.recipesTilesTileLove}
                    onClick={(e) => {
                      like(d.id);
                      setData(
                        data.map((item: any) => {
                          if (item.id === d.id) {
                            item.isFavorite = !item.isFavorite;
                          }
                          return item;
                        })
                      );
                    }}
                  >
                    {d.isFavorite ? (
                      <ReactSVG src="/assets/icon/love1.svg" />
                    ) : (
                      <ReactSVG src="/assets/icon/love0.svg" />
                    )}
                  </div>
                  <a href={`/recipe?id=${d.id}`}>
                    <div
                      className={styles.recipesTilesTile}
                      style={{ backgroundImage: `url(${d.media})` }}
                    />
                  </a>
                  <a href={`/recipe?id=${d.id}`}>
                    <p className={styles.recipesTilesTileTitle}>{d.title}</p>
                  </a>
                </div>
              ))
            : null}
        </div>
        {!data.length && (
          <p style={{ textAlign: "center", marginBottom: 32 }}>
            {t("noSearchResults")}
          </p>
        )}
        <p>{t("RecipesListingPage.info")}</p>
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            generateItems();
            setTimeout(() => ev.target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <BottomBlob />
        {load && <Loader />}
      </div>
    </Menu>
  );
};
