import { IonContent, IonPage } from "@ionic/react";
import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../../components/PrimaryButton";
import { Accordion } from "../../components/Accordion";
import { ReactSVG } from "react-svg";
import { BottomBlobDosha } from "../../components/BottomBlobDosha";
import { useHistory } from "react-router";

export const DoshaTestPage = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [stage, setStage] = useState(0);
  const data = [
    // @ts-ignore
    ...t("DoshaTestPage.test.0.questions", { returnObjects: true }),
  ];
  const accordionData = [
    // @ts-ignore
    ...t("DoshaTestPage.faq", { returnObjects: true }),
  ];
  const [steps, setSteps] = useState(data.length);
  const [answers, setAnswers] = useState(Array(data.length));

  const check = () => {
    const checkedValue = (
      document.querySelector("input:checked") as HTMLInputElement
    )?.value;
    const arr = answers;
    arr[step] = checkedValue;
    setAnswers(arr);
  };
  const next = () =>
    step + 1 < steps ? setStep(step + 1) : countDosha() && setStage(2);
  const back = () => (step - 1 >= 0 ? setStep(step - 1) : setStage(0));

  const [result, setResult] = useState<any>();
  const countDosha = () => {
    const p = answers.filter((a) => a === "P").length;
    const k = answers.filter((a) => a === "K").length;
    const v = answers.filter((a) => a === "V").length;
    const arr = [
      {
        name: "Vata",
        answers: v,
        value: Math.round((v / steps) * 100),
        description: t("DoshaTestPage.test.0.summary.vata"),
        img: "/assets/icon/doshaVata.svg",
        color: "#94a3cb",
      },
      {
        name: "Pitta",
        answers: p,
        value: Math.round((p / steps) * 100),
        description: t("DoshaTestPage.test.0.summary.pitta"),
        img: "/assets/icon/doshaPitta.svg",
        color: "#e24949",
      },
      {
        name: "Kapha",
        answers: k,
        value: Math.round((k / steps) * 100),
        description: t("DoshaTestPage.test.0.summary.kapha"),
        img: "/assets/icon/doshaKapha.svg",
        color: "#088179",
      },
    ];
    arr.sort((a, b) => b.value - a.value);
    setDosha(arr[0]?.name?.toLowerCase());
    getBlogData(arr[0]?.name?.toLowerCase());
    setResult(arr);
    return true;
  };
  //---  GET BLOG DATA
  const [blog, setBlog] = useState([]);
  const getBlogData = (dsha = "") => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_BLOG_LISTING) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_BLOG_LISTING +
          "?limit=3" +
          (dsha ? "&dosha=" + dsha : ""),
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setBlog(res.data.items.slice(0, 3));
        }
      });
    }
  };

  const history = useHistory();
  //---
  const setDosha = (dosha) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append("dosha", dosha);

    const requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_USER_DOSHA) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_DOSHA,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
        }
      });
    }
  };
  //--
  if (stage === 1) {
    return (
      <Menu title={t("DoshaTestPage.test.0.title")} link="/doshatest">
        <div className={styles.doshaTest}>
          <div className={styles.progressBox}>
            <div className={styles.steps}>
              {step + 1}/{steps}
            </div>
            <div className={styles.progress}>
              <div
                className={styles.progressBar}
                style={{ width: `calc((100% / ${steps}) * ${step + 1})` }}
              />
            </div>
          </div>
          <div>
            <h2>{data[step].question}</h2>
            <div className={styles.answers}>
              <label htmlFor="a" className={styles.answersAnswer}>
                <input
                  key={1 + step + "inpt"}
                  type="radio"
                  value={data[step].answers[0].value}
                  defaultChecked={answers[step] === data[step].answers[0].value}
                  name="answer"
                  id="a"
                  onChange={check}
                />
                <label htmlFor="a">{data[step].answers[0].answer}</label>
              </label>
              <label htmlFor="b" className={styles.answersAnswer}>
                <input
                  key={2 + step + "inpt"}
                  type="radio"
                  value={data[step].answers[1].value}
                  defaultChecked={answers[step] === data[step].answers[1].value}
                  name="answer"
                  id="b"
                  onChange={check}
                />
                <label htmlFor="b">{data[step].answers[1].answer}</label>
              </label>
              <label htmlFor="c" className={styles.answersAnswer}>
                <input
                  key={3 + step + "inpt"}
                  type="radio"
                  value={data[step].answers[2].value}
                  defaultChecked={answers[step] === data[step].answers[2].value}
                  name="answer"
                  id="c"
                  onChange={check}
                />
                <label htmlFor="c">{data[step].answers[2].answer}</label>
              </label>
            </div>
          </div>
          <div className={styles.btns}>
            <button className={styles.btnsBtn} onClick={back}>
              {t("DoshaTestPage.test.0.back")}
            </button>
            <button
              className={`${styles.btnsBtn} ${styles.btnsBtnActive}`}
              onClick={() => answers[step] && next()}
            >
              {t("DoshaTestPage.test.0.next")}
            </button>
          </div>
        </div>
        <BottomBlobDosha data={step} />
      </Menu>
    );
  } else if (stage === 2) {
    return (
      <Menu title={t("DoshaTestPage.test.0.title")} link="/doshatest">
        <div className={styles.doshaTest}>
          <div>
            <div className={styles.doshaTestDescription}>
              <h2>{t("DoshaTestPage.test.0.summary.title")}</h2>
              <h3>
                {t("DoshaTestPage.test.0.summary.subtitle") +
                  " " +
                  result[0].name}
              </h3>
              <div className={styles.progress}>
                {result &&
                  result.map((item: any) => (
                    <div
                      className={styles.progressBar}
                      style={{
                        width: `${item.value}%`,
                        backgroundColor: item.color,
                      }}
                    />
                  ))}
              </div>

              <div className={styles.doshaTestDescriptionItems}>
                {result &&
                  result.map((item: any) => (
                    <div className={styles.doshaTestDescriptionItem}>
                      <ReactSVG src={item.img} style={{ fill: item.color }} />
                      <div>
                        <b>
                          {item.name}{" "}
                          <span style={{ color: item.color }}>
                            ({item.value + "%"} - {item.answers}/{steps})
                          </span>
                        </b>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {accordionData
              .filter(
                (item) => item.value === result[0].name || item.value === ""
              )
              .map((item, index) => (
                <Accordion title={item.title}>
                  <div className={styles.accrodionContent}>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                </Accordion>
              ))}
            <div className={styles.dashboardDiscover}>
              <p className={styles.dashboardDiscoverHeader}>
                {t("DashboardPage.discover")}
              </p>
              <div className={styles.dashboardDiscoverList}>
                {blog.map((item: any, index) => (
                  <a
                    key={"blog" + index}
                    href={`/discoveritem?id=${item.id}`}
                    className={styles.dashboardDiscoverListItem}
                    style={{
                      backgroundImage: `url('${item.media}')`,
                    }}
                  >
                    <p>{item.title}</p>
                  </a>
                ))}
              </div>
              <PrimaryButton
                text={t("DashboardPage.discoverBtn")}
                onClick={() => {
                  setTimeout(() => {
                    history.push(
                      `/discover?dosha=${result[0].name.toLowerCase()}`
                    );
                    history.go(0);
                  }, 100);
                }}
              />
            </div>
          </div>
        </div>
        <BottomBlobDosha />
      </Menu>
    );
  } else {
    return (
      <Menu title={t("DoshaTestPage.test.0.title")} goToMenu>
        <div className={styles.doshaTest}>
          <div className={styles.doshaTestStart}>
            <h2>{t("DoshaTestPage.test.0.introduction.title")}</h2>
            <p>{t("DoshaTestPage.test.0.introduction.desc1")}</p>
            <p>{t("DoshaTestPage.test.0.introduction.desc2")}</p>
            <p>{t("DoshaTestPage.test.0.introduction.desc3")}</p>
            <PrimaryButton
              text={t("DoshaTestPage.test.0.introduction.start")}
              onClick={() => {
                setAnswers(Array(data.length));
                setStage(1);
              }}
            />
          </div>
        </div>
        <BottomBlobDosha />
      </Menu>
    );
  }
};
