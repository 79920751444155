import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { IonContent, IonPage } from "@ionic/react";
import { ReactSVG } from "react-svg";
import { useState } from "react";
import { useHistory } from "react-router";

export const OnboardingPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [firstSlide, setFirstSlide] = useState(true);
  const [slide, setSlide] = useState(0);
  const data = [
    {
      img: "/assets/images/onboarding1.webp",
      title: t("OnboardingPage.steps.0"),
    },
    {
      img: "/assets/images/onboarding2.webp",
      title: t("OnboardingPage.steps.1"),
    },
    {
      img: "/assets/images/onboarding3.webp",
      title: t("OnboardingPage.steps.2"),
    },
    {
      img: "/assets/images/onboarding4.webp",
      title: t("OnboardingPage.steps.3"),
    },
  ];
  const NextSlide = () => {
    if (slide < data.length - 1) {
      setSlide(slide + 1);
    } else {
      localStorage.setItem("onBoarding", "true");
      history.push("/login");
    }
  };
  return (
    <IonPage id="main-content">
      <IonContent>
        <div className={styles.onboardingSlideWrapper}>
          {firstSlide ? (
            <div className={styles.onboarding}>
              <div>
                <p className={styles.onboardingSubtitle}>
                  {t("OnboardingPage.title")}
                </p>
                <p className={styles.onboardingTitle}>
                  {t("OnboardingPage.subtitle")}
                </p>
                <button
                  className={styles.onboardingBtn}
                  onClick={() => setFirstSlide(false)}
                >
                  <p> {t("OnboardingPage.more")}</p>
                  <ReactSVG src="assets/icon/left.svg" />
                </button>
                <button
                  onClick={() => {
                    localStorage.setItem("onBoarding", "true");
                    history.push("/login");
                  }}
                  className={`${styles.onboardingBtn} ${styles.onboardingBtnSkip}`}
                >
                  {t("OnboardingPage.skip")}
                </button>
              </div>
            </div>
          ) : (
            <div className={styles.onboardingSlide}>
              <div className={styles.onboardingDots}>
                {data.map((item, index) => (
                  <div
                    onClick={() => setSlide(index)}
                    className={`${styles.onboardingDotsDot} ${
                      slide === index ? styles.onboardingDotsDotActive : ""
                    }`}
                  />
                ))}
              </div>

              <img
                className={styles.onboardingSlideImage}
                src={data[slide].img}
              />
              <p className={styles.onboardingSlideTitle}>{data[slide].title}</p>
              <button onClick={NextSlide} className={styles.onboardingBtnNext}>
                <ReactSVG src="assets/icon/left.svg" />
              </button>
              <button
                onClick={() => {
                  localStorage.setItem("onBoarding", "true");
                  history.push("/login");
                }}
                className={`${styles.onboardingBtn} ${styles.onboardingBtnSkip}`}
              >
                {t("OnboardingPage.skip")}
              </button>
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};
