import styles from "./style.module.scss";
import { Menu } from "../../components/Menu";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { Calendar } from "react-multi-date-picker";
import { addLeadingZero } from "../../helpers/addLeadingZero";
import { datesBetween } from "../../helpers/datesBetween";
import { AddIngredient } from "../../components/AddIngredient";
import { useHistory } from "react-router";
import { Capacitor } from "@capacitor/core";
import { FileOpener } from "@capacitor-community/file-opener";
import { Filesystem, Directory } from "@capacitor/filesystem";
import writeBlob from "capacitor-blob-writer";
import { Loader } from "../../components/Loader";

export const IngredientListPage = () => {
  const [load, setLoad] = useState(false);
  const [isNative, setIsNative] = useState<boolean>(
    Capacitor.isNativePlatform()
  );
  const history = useHistory();
  const { t } = useTranslation();
  const [unchecked, setUnchecked] = useState<any>([]);
  const [checked, setChecked] = useState<any>([]);
  const downloadPDF = (fromDate?: string, toDate?: string) => {
    setLoad(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let start;
    let end;
    if (
      values[0]?.day &&
      values[1]?.day &&
      values[0]?.month?.number &&
      values[1]?.month?.number &&
      values[0]?.year &&
      values[1]?.year
    ) {
      start = `${addLeadingZero(values[0].day)}-${addLeadingZero(
        values[0].month.number
      )}-${values[0].year}`;
      end = `${addLeadingZero(values[1].day)}-${addLeadingZero(
        values[1].month.number
      )}-${values[1].year}`;
    }

    const x = !!start && !!end ? `?startDate=${start}&stopDate=${end}` : "";
    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_INGREDIENTLIST_PDF
    ) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_INGREDIENTLIST_PDF +
          x,
        requestOptions
      )
        .then((res) => res.json())
        .then((res) => {
          window.location.href = res.data;
          setLoad(false);
        });

      // .then((res) => res.blob())
      // .then((blob) => {
      //   if (isNative && process.env.REACT_APP_APP_URL) {
      //     writeBlob({
      //       path: "document.pdf",
      //       directory: Directory.Documents,
      //       blob: blob,
      //       fast_mode: true,
      //       recursive: true,
      //       on_fallback(error) {},
      //     }).then((reps) => {
      //       setLoad(false);
      //       FileOpener.open({
      //         filePath: reps,
      //         contentType: "application/pdf",
      //         openWithDefault: true,
      //       })
      //         .then((resp) => {})
      //         .catch((error) => {});
      //     });
      //     return;
      //   }
      //   const url = window.URL.createObjectURL(blob);
      //   const a = document.createElement("a");
      //   a.href = url;
      //   a.download = "document.pdf";
      //   document.body.appendChild(a);
      //   a.click();
      //   a.remove();
      //   setLoad(false);
      // });
    }
  };
  const getPlanRecipes = (fromDate?: string, toDate?: string) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const x =
      !!fromDate && !!toDate ? `?startDate=${fromDate}&stopDate=${toDate}` : "";
    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_INGREDIENTLIST_READ
    ) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_INGREDIENTLIST_READ +
          x,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          try {
            setUnchecked(Object.values(res.data.unchecked));
          } catch {
            setUnchecked([]);
          }
          try {
            setChecked(Object.values(res.data.checked));
          } catch {
            setChecked([]);
          }
        }
      });
    }
  };
  const checkItem = (id) => {
    let start;
    let end;
    if (
      values[0]?.day &&
      values[1]?.day &&
      values[0]?.month?.number &&
      values[1]?.month?.number &&
      values[0]?.year &&
      values[1]?.year
    ) {
      start = `${addLeadingZero(values[0].day)}-${addLeadingZero(
        values[0].month.number
      )}-${values[0].year}`;
      end = `${addLeadingZero(values[1].day)}-${addLeadingZero(
        values[1].month.number
      )}-${values[1].year}`;
    }
    const x = !!start && !!end ? `&startDate=${start}&stopDate=${end}` : "";

    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_INGREDIENTLIST_CHECK
    ) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_INGREDIENTLIST_CHECK +
          `?id=${id}` +
          x,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          setRefresh(!refresh);
        }
      });
    }
  };
  useEffect(() => {
    getPlanRecipes();
  }, []);
  const [side, setSide] = useState<any>(true);

  const [refresh, setRefresh] = useState(false);
  const [values, setValues] = useState<any>({});
  const dzisiaj = new Date();
  const zaTydzien = new Date();
  zaTydzien.setDate(zaTydzien.getDate() + 6);
  const dzisiajFormatted = `${dzisiaj.getDate().toString().padStart(2, "0")}.${(
    dzisiaj.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")} - ${zaTydzien.getDate().toString().padStart(2, "0")}.${(
    zaTydzien.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}`;
  const [displayDate, setDisplayDate] = useState<any>(dzisiajFormatted);
  useEffect(() => {
    if (
      values[0]?.day &&
      values[1]?.day &&
      values[0]?.month?.number &&
      values[1]?.month?.number &&
      values[0]?.year &&
      values[1]?.year
    ) {
      const start = `${addLeadingZero(values[0].day)}-${addLeadingZero(
        values[0].month.number
      )}-${values[0].year}`;
      const end = `${addLeadingZero(values[1].day)}-${addLeadingZero(
        values[1].month.number
      )}-${values[1].year}`;

      getPlanRecipes(start, end);
      getPlanRecipes(start, end);
      setDisplayDate(
        `${addLeadingZero(values[0].day)}.${addLeadingZero(
          values[0].month.number
        )} - ${addLeadingZero(values[1].day)}.${addLeadingZero(
          values[1].month.number
        )}`
      );
    } else {
      getPlanRecipes();
      getPlanRecipes();
    }
  }, [values, refresh]);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const deleteItem = (id: string) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_INGREDIENTLIST_DELETE
    ) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_INGREDIENTLIST_DELETE +
          `?id=${id}`,
        requestOptions
      ).then((res) => setRefresh(!refresh));
    }
  };
  return (
    <Menu title={t("IngredientListPage.title")}>
      <div className={styles.ingredients}>
        <button
          className={styles.addIngredientOpenBtn}
          onClick={() => {
            setTimeout(() => {
              history.push("/ingredientadd");
              history.go(0);
            }, 100);
          }}
        >
          <ReactSVG src="/assets/icon/plus.svg" />
        </button>
        <div className={styles.ingredientsTopBtns}>
          <button
            className={`${styles.ingredientsTopBtnsBtn} ${
              !side && styles.ingredientsTopBtnsBtnActive
            }`}
            onClick={() => setSide(false)}
          >
            <ReactSVG src="/assets/icon/left_side_icon.svg" />
          </button>
          <button
            className={styles.ingredientsTopBtnsBtn}
            onClick={() => downloadPDF()}
          >
            <ReactSVG src="/assets/icon/download-outline.svg" />
          </button>
          <button
            className={`${styles.ingredientsTopBtnsBtn} ${
              side && styles.ingredientsTopBtnsBtnActive
            }`}
            onClick={() => setSide(true)}
          >
            <ReactSVG src="/assets/icon/right_side_icon.svg" />
          </button>
        </div>
        <div className={styles.weeklyTopBarDate}>
          <p
            className={styles.weeklyTopBarDateLabel}
            onClick={() => setCalendarOpen(!calendarOpen)}
          >
            {displayDate?.replace(/\./g, "/") ||
              t("IngredientListPage.calendar")}
          </p>
          {calendarOpen && (
            <>
              <Calendar
                className={styles.weeklyTopBarDateCalendar}
                value={values}
                onChange={setValues}
                range
                rangeHover
              />
              <div
                className={styles.weeklyTopBarDateCalendarOverlay}
                onClick={() => setCalendarOpen(!calendarOpen)}
              />
            </>
          )}
        </div>
        <div className={styles.ingredientsList}>
          {unchecked.map((itemT: any, index: number) => (
            <div
              key={"unchecked" + index}
              className={styles.ingredientsListItem}
              style={{ backgroundColor: itemT?.categoryColor || "#fff" }}
            >
              <p
                className={`${styles.ingredientsListItemHeader} ${
                  side && styles.ingredientsListItemHeaderSide
                }`}
              >
                {itemT.categoryName}
              </p>
              <div className={styles.ingredientsListItemIngredients}>
                {!!itemT?.ingredients &&
                  Object.values(itemT.ingredients).map((item: any) => (
                    <div
                      key={item.id + item.isChecked.toString()}
                      className={`${
                        styles.ingredientsListItemIngredientsIngredient
                      } ${
                        side &&
                        styles.ingredientsListItemIngredientsIngredientSide
                      }`}
                    >
                      <input
                        type="checkbox"
                        id={item.id}
                        name={item.id}
                        value={item.id}
                        onChange={() => checkItem(item.id)}
                      />
                      <label htmlFor={item.id}>
                        {item.name} {item.count?.toFixed(2)?.replace(".00", "")}{" "}
                        {item.count <= 1 ? item.unitSingular : item.unitPlural}
                      </label>
                      {!itemT.categoryColor ? (
                        <button onClick={() => deleteItem(item.id)}>
                          <ReactSVG src="/assets/icon/delete.svg" />
                        </button>
                      ) : (
                        <div />
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ))}
          {checked.length > 0 && (
            <div
              className={styles.ingredientsListItem}
              style={{
                backgroundColor: "#d1d1d1",
                color: "#fff",
                paddingBottom: 64,
              }}
            >
              <p
                className={`${styles.ingredientsListItemHeader} ${
                  side && styles.ingredientsListItemHeaderSide
                }`}
              >
                {t("IngredientListPage.done")}
              </p>
              <div className={styles.ingredientsListItemIngredients}>
                {checked.map((itemT: any) => (
                  <>
                    {!!itemT?.ingredients &&
                      Object.values(itemT.ingredients).map((item: any) => (
                        <div
                          key={item.id + item.isChecked.toString()}
                          className={`${
                            styles.ingredientsListItemIngredientsIngredient
                          } ${
                            side &&
                            styles.ingredientsListItemIngredientsIngredientSide
                          }
                    ${styles.ingredientsListItemIngredientsIngredientChecked}`}
                        >
                          <input
                            type="checkbox"
                            id={item.id}
                            name={item.id}
                            value={item.id}
                            onChange={() => checkItem(item.id)}
                            defaultChecked
                          />
                          <label htmlFor={item.id}>
                            {item.name} {item.count}{" "}
                            {item.count <= 1
                              ? item.unitSingular
                              : item.unitPlural}
                          </label>
                          {!itemT.categoryColor ? (
                            <button onClick={() => deleteItem(item.id)}>
                              <ReactSVG src="/assets/icon/delete.svg" />
                            </button>
                          ) : (
                            <div />
                          )}
                        </div>
                      ))}
                  </>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {load && <Loader />}
    </Menu>
  );
};
