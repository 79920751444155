import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ReactSVG } from "react-svg";
import { datesBetween } from "../../helpers/datesBetween";
import { ModalPencil } from "../ModalPencil";
import { ModalPlus } from "../ModalPlus";
import { PrimaryButton } from "../PrimaryButton";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";

const Column = ({ current, data, setRefresh, onClick }: any) => {
  const { t } = useTranslation();

  const week = [
    t("weekday.sun"),
    t("weekday.mon"),
    t("weekday.tue"),
    t("weekday.wed"),
    t("weekday.thu"),
    t("weekday.fri"),
    t("weekday.sat"),
  ];
  const date = new Date(data.day.split("-").reverse().join("-"));
  const day = date.getDate();
  const weekday = date.getDay();
  const [open, setOpen] = useState(false);
  return (
    <div
      onClick={onClick}
      className={`${styles.Column} ${current && styles.ColumnCurrent}`}
    >
      <div className={styles.ColumnHeader}>
        <div className={styles.ColumnDay}>{week[weekday]}</div>
        <div className={styles.ColumnDay}>{day}</div>
      </div>
      <div className={styles.ColumnContent}>
        {data.data.map((item: any) => (
          <Item data={item} setRefresh={setRefresh} />
        ))}
      </div>
    </div>
  );
};

const Item = ({ data, setRefresh }: any) => {
  return (
    <div className={styles.Item}>
      <img src={data.recipe.media} alt="img" />
      <p>{data.recipe.name}</p>
    </div>
  );
};

export const WeeklyPlanSelect = ({ data, setRefresh, dates }: any) => {
  const { t } = useTranslation();
  const history = useHistory();

  const arr: any = [];
  dates.forEach((item: any) => {
    arr.push({
      day: item,
      data: [],
    });
  });
  data.forEach((item: any) => {
    if (arr.find((i: any) => i.day === item.day)) {
      arr.find((i: any) => i.day === item.day).data.push(item);
      return;
    }
  });
  const [current, setCurrent] = useState<any>();
  const [day, setDay] = useState<any>();
  useEffect(() => {
    setDay(arr[current]?.day);
  }, [current]);

  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const [recipeId, setRecipeId] = useState<any>(query.get("recipeId"));
  const [servings, setServings] = useState<any>(query.get("servings"));
  const [recipePlanId, setRecipePlanId] = useState<any>(
    query.get("recipePlanId")
  );
  const [btnText, setBtnText] = useState<any>(t("WeeklyPlanSelect.btn"));
  const createPlan = async (recipeId, date, history) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append("recipeId", recipeId);
    formdata.append("date", date);
    formdata.append("servings", servings);

    const requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_RECIPES_PLAN_CREATE
    ) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES_PLAN_CREATE,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          history.push(`/weeklyplan?showSuccess=true`);
          history.go(0);
        }
      });
    }
  };
  const updatePlan = async (recipePlanId, recipeId, date, history) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append("recipePlanId", recipePlanId);
    formdata.append("recipeId", recipeId);
    formdata.append("date", date);

    const requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_RECIPES_PLAN_UPDATE
    ) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES_PLAN_UPDATE,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setBtnText(t("WeeklyPlanSelect.moved"));
          setTimeout(() => {
            history.push(`/weeklyplan`);
            history.go(0);
          }, 1000);
        }
      });
    }
  };

  return (
    <div className={styles.Container}>
      {arr.map((item: any, index: number) => (
        <Column
          current={current === index}
          data={item}
          setRefresh={setRefresh}
          onClick={() => setCurrent(index)}
        />
      ))}
      <button
        className={`${!(current + 1) && styles.disabled}`}
        onClick={() => {
          if (recipePlanId) {
            updatePlan(recipePlanId, recipeId, day, history);
          } else {
            createPlan(recipeId, day, history);
          }
        }}
      >
        {btnText}
      </button>
    </div>
  );
};
