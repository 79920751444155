import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ReactSVG } from "react-svg";
import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";
import { Loader } from "../../components/Loader";

export const HealitPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  //---  GET USER DATA
  const [userDosha, setUserDosha] = useState<any>("");
  const [userGender, setUserGender] = useState<any>("");
  const getUserData = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_USER_READ) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_READ,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setUserDosha(res.data.dosha ? res.data.dosha : "generic");
          if (res.data.gender != "man" && res.data.gender != "woman") {
            const genders = ["man", "woman"];
            const randomIndex = Math.floor(Math.random() * genders.length);
            setUserGender(genders[randomIndex]);
          } else {
            setUserGender(res.data.gender);
          }
        }
      });
    }
  };
  const [load, setLoad] = useState(true);
  useEffect(() => {
    getUserData();
    setTimeout(() => {
      setLoad(false);
    }, 1000);
  }, []);
  //---
  const [description, setDescription] = useState<any>("");
  const getPosts = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_CMS_POSTS) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_CMS_POSTS +
          `?dosha=${userDosha}&type=healitDescription`,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setDescription(
            res.data.items.filter((item: any) => item.bodyType === null)[0]
              .content
          );
        }
      });
    }
  };
  useEffect(() => {
    if (userDosha) getPosts();
  }, [userDosha]);
  //---
  const data = [
    {
      name: t("HealitPage.body.chest"),
      link: `/healitlisting?dosha=${userDosha?.toLowerCase()}&bodyType=${"chest"}`,
    },
    {
      name: t("HealitPage.body.colon"),
      link: `/healitlisting?dosha=${userDosha?.toLowerCase()}&bodyType=${"colon area"}`,
    },
    {
      name: t("HealitPage.body.head"),
      link: `/healitlisting?dosha=${userDosha?.toLowerCase()}&bodyType=${"head"}`,
    },
    {
      name: t("HealitPage.body.arms"),
      link: `/healitlisting?dosha=${userDosha?.toLowerCase()}&bodyType=${"arms"}`,
    },
    {
      name: t("HealitPage.body.stomach"),
      link: `/healitlisting?dosha=${userDosha?.toLowerCase()}&bodyType=${"stomach"}`,
    },
    {
      name: t("HealitPage.body.legs"),
      link: `/healitlisting?dosha=${userDosha?.toLowerCase()}&bodyType=${"legs"}`,
    },
    {
      name: t("HealitPage.body.back"),
      link: `/healitlisting?dosha=${userDosha?.toLowerCase()}&bodyType=${"back"}`,
    },
  ];
  const [inpt, setInpt] = useState<any>("");
  return (
    <Menu title={t("Heal'it")}>
      <div className={styles.healit}>
        <div
          className={styles.healitSearch}
          style={{
            backgroundColor:
              userDosha?.toLowerCase() === "kapha"
                ? "#e3fff8"
                : userDosha?.toLowerCase() === "pitta"
                ? "#ffe8e9"
                : userDosha?.toLowerCase() === "vata"
                ? "#f3f9ff"
                : userDosha?.toLowerCase() === "generic"
                ? "#fbf6e7"
                : "transparent",
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              history.push(
                `/healitlisting?dosha=${userDosha?.toLowerCase()}&search=${inpt}`
              );
              history.go(0);
            }}
          >
            <input
              type="text"
              placeholder={t("HealitPage.search")}
              onChange={(e) => setInpt(e.target.value)}
            />
          </form>
          <img
            src="/assets/icon/search.svg"
            alt="search"
            onClick={() => {
              history.push(
                `/healitlisting?dosha=${userDosha?.toLowerCase()}&search=${inpt}`
              );
              history.go(0);
            }}
          />
        </div>
        <div
          className={styles.healitDosha}
          style={{
            backgroundColor:
              userDosha?.toLowerCase() === "kapha"
                ? "#088179"
                : userDosha?.toLowerCase() === "pitta"
                ? "#e24a49"
                : userDosha?.toLowerCase() === "vata"
                ? "#94a3cb"
                : "transparent",
          }}
        >
          <ReactSVG
            src={`/assets/icon/dosha${
              userDosha?.charAt(0).toUpperCase() + userDosha?.slice(1)
            }.svg`}
          />
          <p>{userDosha?.charAt(0).toUpperCase() + userDosha?.slice(1)}</p>
        </div>
        <div className={styles.healitHuman}>
          {data.map((item, index) => (
            <a href={item.link} className={styles.healitHumanItem}>
              <p>{item.name}</p>
            </a>
          ))}
          <img
            src={`/assets/body/${userGender?.toLowerCase()}${userDosha?.toLowerCase()}.png`}
          />
        </div>
        <div className={styles.healitBlobTop}>
          <img src={`/assets/body/${userDosha?.toLowerCase()}.png`} />
        </div>
        <p className={styles.healitInfo}>{t("HealitPage.description")}</p>
        <div
          className={styles.healitContent}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <ReactSVG
          className={styles.healitDoshaIcon}
          src={`/assets/icon/dosha${userDosha}.svg`}
        />
        <div className={styles.healitBlobBottom}>
          <img src={`/assets/body/${userDosha?.toLowerCase()}.png`} />
        </div>
      </div>
      {load && <Loader />}
    </Menu>
  );
};
