import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { ReactSVG } from "react-svg";
import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";
import { Loader } from "../../components/Loader";

export const DiscoverListingPage = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const [dosha, setDosha] = useState(query.get("dosha") || "");
  const { t } = useTranslation();
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [load, setLoad] = useState(false);
  const getPosts = async (data, dsha = "") => {
    setLoad(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const phrase = search ? `&phrase=${search}` : "";
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_BLOG_LISTING) {
      await fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_BLOG_LISTING +
          `?page=${page}` +
          phrase +
          (dsha ? "&dosha=" + dsha : ""),
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setData([...data, ...res.data.items]);
        }
      });
    }
    setLoad(false);
  };

  useEffect(() => {
    getPosts(data, dosha);
  }, [page]);

  useEffect(() => {
    setPage(1);
    getPosts([], dosha);
  }, [search]);

  return (
    <Menu title={t("DiscoverListingPage.title")}>
      <div className={styles.healit}>
        <div
          className={styles.healitSearch}
          style={{
            backgroundColor: "#eee",
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <DebounceInput
              style={{ width: "100%" }}
              type="text"
              placeholder={"Search..."}
              debounceTimeout={300}
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
          <img src="/assets/icon/search.svg" alt="search" />
        </div>
        <div className={styles.healitDiscoverList}>
          {data.length
            ? data.map((item: any, index) => (
                <a
                  href={`/discoveritem?id=${item.id}`}
                  className={styles.healitDiscoverListItem}
                  style={{
                    backgroundImage: `url('${item.media}')`,
                  }}
                >
                  <p>{item.title}</p>
                </a>
              ))
            : null}
        </div>
        {!data.length && (
          <p style={{ textAlign: "center", marginBottom: 32 }}>
            {t("noSearchResults")}
          </p>
        )}
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            setPage(page + 1);
            setTimeout(() => ev.target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        {load && <Loader />}
      </div>
    </Menu>
  );
};
