import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ReactSVG } from "react-svg";
import styles from "./style.module.scss";

export const ModalPlus = ({ open, setOpen, data }: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  return open ? (
    <div className={styles.ModalPlus}>
      <ReactSVG
        className={styles.ModalPlusExit}
        src="/assets/icon/plus.svg"
        onClick={() => setOpen(false)}
      />
      <div className={styles.ModalPlusTiles}>
        <div
          className={styles.ModalPlusTilesTile}
          onClick={() => {
            setOpen(false);
            localStorage.setItem("recipeDate", data.day);
            history.push(`/favorite`);
          }}
        >
          <ReactSVG src="/assets/icon/menu1.svg" />
          <p>{t("Components.ModalPlus.favourites")}</p>
        </div>
        <div
          className={styles.ModalPlusTilesTile}
          onClick={() => {
            setOpen(false);
            localStorage.setItem("recipeDate", data.day);
            history.push(`/recipes`);
          }}
        >
          <ReactSVG src="/assets/icon/add_recipe.svg" />
          <p>{t("Components.ModalPlus.recipes")}</p>
        </div>
      </div>
    </div>
  ) : null;
};
