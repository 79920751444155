import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { Input } from "../../components/Input";
import { ErrorLabel } from "../../components/ErrorLabel";
import { Select } from "../../components/Select";
import { PrimaryButton } from "../../components/PrimaryButton";
import YupPassword from "yup-password";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import {
  IonDatetime,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPopover,
  IonToggle,
} from "@ionic/react";
import { DoshaCheck } from "../../components/DoshaCheck";
import { useHistory } from "react-router";
import { Accordion } from "../../components/Accordion";
import { LocalNotifications } from "@capacitor/local-notifications";
import { Loader } from "../../components/Loader";

YupPassword(Yup);

export const QuestionnairePage = () => {
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const weekdays = [
    {
      value: "monday",
      label: t("QuestionnairePage.weekdays.monday"),
    },
    {
      value: "tuesday",
      label: t("QuestionnairePage.weekdays.tuesday"),
    },
    {
      value: "wednesday",
      label: t("QuestionnairePage.weekdays.wednesday"),
    },
    {
      value: "thursday",
      label: t("QuestionnairePage.weekdays.thursday"),
    },
    {
      value: "friday",
      label: t("QuestionnairePage.weekdays.friday"),
    },
    {
      value: "saturday",
      label: t("QuestionnairePage.weekdays.saturday"),
    },
    {
      value: "sunday",
      label: t("QuestionnairePage.weekdays.sunday"),
    },
  ];
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const [defaultDiets, setDefaultDiets] = useState();
  const [defaultAllergies, setDefaultAllergies] = useState();
  const [defaultCategories, setDefaultCategories] = useState();
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  //--- POPOVER
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  //---

  //---  GET DIETS
  const [diets, setDiets] = useState<any>([]);
  const getDiets = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_USER_DIETS) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_DIETS,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          const arr: any = [
            // {
            //   value: "",
            //   label: "None",
            // },
          ];
          res.data.forEach((item: any) => {
            arr.push({
              value: item.id,
              label: item.name,
            });
          });
          setDiets(arr);
        }
      });
    }
  };
  //---
  //---  GET USER DATA
  const [userData, setUserData] = useState<any>([]);
  const getUserData = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_USER_READ) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_READ,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setUserData(res.data);
          setFieldValue("name", res.data.name);
          setFieldValue("email", res.data.email);
          setFieldValue("peoplecook", res.data.servings);
          if (res.data.reminder) setIsChecked(true);
          setFieldValue("weekday", res.data.reminder.split("-")[0]);
          setFieldValue(
            "time",
            "2022-05-31T" + res.data.reminder.split("-")[1]
          );
          if (timeRef.current && res.data.reminder) {
            timeRef.current.value =
              "2022-05-31T" + res.data.reminder.split("-")[1];
          }
          setDefaultDiets(
            res.data.attributes
              .filter((item: any) => item.categoryName == "Diets")
              .map((item: any) => item.attributeId)
          );
          setDefaultAllergies(
            res.data.attributes
              .filter((item: any) => item.categoryName == "Allergies")
              .map((item: any) => item.attributeId)
          );
          setFieldValue("dosha", res.data.dosha);
          setDefaultCategories(
            res.data.recipeCategory ? res.data.recipeCategory : "null"
          );
        }
      });
    }
  };
  //---
  //---  GET ALLERGIES
  const [allergies, setAllergies] = useState<any>([]);
  const getAllergies = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_USER_ALLERGIES) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_ALLERGIES,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          const arr: any = [];
          res.data.forEach((item: any) => {
            arr.push({
              value: item.id,
              label: item.name,
            });
          });
          setAllergies(arr);
        }
      });
    }
  };
  //---
  //---  GET CATEGORIES
  const [categories, setCategories] = useState<any>([]);
  const getCategories = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_RECIPES_CATEGORIES
    ) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES_CATEGORIES,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          const arr: any = [];
          res.data
            .filter((item: any) => item.usedInWeeklyPlan === true)
            .forEach((item: any) => {
              arr.push({
                value: item.id,
                label: item.names,
              });
            });
          setCategories(arr);
        }
      });
    }
  };
  //---
  const LoginSchema = Yup.object().shape({});

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
  } = useFormik({
    validationSchema: LoginSchema,
    initialValues: {
      diet: "",
      allergies: "",
      peoplecook: "",
      categories: "",
      weekday: "",
      time: "",
      dosha: "",
    },
    onSubmit: async (values: any) => {
      setLoading(true);
      const myHeaders = new Headers();
      const token = localStorage.getItem("token");
      myHeaders.append("Authorization", `Bearer ${token}`);
      const formdata = new FormData();
      formdata.append("dosha", values.dosha);
      formdata.append("servings", values.peoplecook);
      formdata.append("userId", userData.id);
      if (values.weekday && values.time && isChecked) {
        formdata.append(
          "reminder",
          values.weekday + "-" + values.time.split("T")[1].split("+")[0]
        );
      } else {
        formdata.append("reminder", "");
      }

      formdata.append("diet[]", values.diet);

      // for (const property in values.allergies) {
      //   formdata.append("allergies[]", values.allergies[property]);
      // }
      formdata.append(
        "recipeCategoryId",
        values.categories === "null" ? "" : values.categories
      );
      const requestOptions: any = {
        method: "POST",
        body: formdata,
        redirect: "follow",
        headers: myHeaders,
      };
      if (
        process.env.REACT_APP_API_URL &&
        process.env.REACT_APP_USER_QUEST_UPDATE
      ) {
        await fetch(
          process.env.REACT_APP_API_URL +
            process.env.REACT_APP_USER_QUEST_UPDATE,
          requestOptions
        ).then(async (response) => {
          if (response.status === 200) {
            localStorage.removeItem("newUser");
            setNewUser(false);
            if (values.weekday && values.time && isChecked) {
              const days = [
                "sunday",
                "monday",
                "tuesday",
                "wednesday",
                "thursday",
                "friday",
                "saturday",
              ];
              const weekday = days.indexOf(values.weekday) + 1;
              const date = new Date(values.time);
              const hours = date.getHours();
              const minutes = date.getMinutes();
              localNotification(weekday, hours, minutes, 0);
            } else {
              localNotificationCancel();
            }
            history.push("/dashboard");
          }
        });
      }
      setLoading(false);
    },
  });
  const [newUser, setNewUser] = useState<any>(false);
  useEffect(() => {
    const x = localStorage.getItem("newUser");
    setNewUser(x);
    getCategories();
    // getAllergies();
    getDiets();
    getUserData();
  }, []);
  const timeRef = useRef<any>(null);
  //---
  const localNotification = async (weekday, hour, minute, second) => {
    if ((await LocalNotifications.requestPermissions()).display === "granted") {
      LocalNotifications.schedule({
        notifications: [
          {
            title: "Spicit",
            body: t("Notifications.localPush"),
            id: 123,
            schedule: {
              on: {
                weekday: weekday,
                hour: hour,
                minute: minute,
                second: second,
              },
            },
          },
        ],
      });
    }
  };
  const localNotificationCancel = async () => {
    if ((await LocalNotifications.requestPermissions()).display === "granted") {
      LocalNotifications.cancel({
        notifications: [
          {
            id: 123,
          },
        ],
      });
    }
  };
  //---
  return (
    <Menu title={t("QuestionnairePage.title")} other goToMenu skip={newUser}>
      <form onSubmit={handleSubmit} className={styles.settings}>
        <Accordion title={t("QuestionnairePage.dosha.title")}>
          <div style={{ marginTop: 20 }} />
          <DoshaCheck
            onChange={handleChange("dosha")}
            onBlur={handleBlur("dosha")}
            defaultValue={userData.dosha}
          />
          <div style={{ marginTop: 20 }} />

          <div className={styles.info}>
            <p>{t("QuestionnairePage.dosha.desc.title")}</p>
            <div
              dangerouslySetInnerHTML={{
                __html: t("QuestionnairePage.dosha.desc.text"),
              }}
            />
          </div>
        </Accordion>
        <Select
          onlyLabel
          name="diet"
          placeholder={t("QuestionnairePage.diet")}
          onChange={handleChange("diet")}
          onBlur={handleBlur("diet")}
          data={diets}
          defaultValue={defaultDiets}
        />
        <ErrorLabel error={errors.diet} touched={touched.diet} />
        <Select
          onlyLabel
          name="categories"
          placeholder={t("QuestionnairePage.categories")}
          onChange={handleChange("categories")}
          onBlur={handleBlur("categories")}
          data={categories}
          defaultValue={defaultCategories}
        />
        <ErrorLabel error={errors.categories} touched={touched.categories} />
        <div className={styles.settingsCook}>
          <div className={styles.settingsCookLabel}>
            {t("QuestionnairePage.meals")}
          </div>
          <Input
            type="number"
            min={0}
            step={1}
            name="peoplecook"
            placeholder={"0"}
            onChange={handleChange("peoplecook")}
            onBlur={handleBlur("peoplecook")}
            defaultValue={userData.servings}
          />
        </div>
        <ErrorLabel error={errors.peoplecook} touched={touched.peoplecook} />
        <div className={styles.reminder}>
          <p>{t("QuestionnairePage.reminder")}</p>
          <IonToggle
            slot="end"
            checked={isChecked}
            onIonChange={() => setIsChecked(!isChecked)}
          />
        </div>
        {isChecked && (
          <div className={styles.settingsDatePicker}>
            <input
              className={styles.settingsTimePicker}
              placeholder={t("QuestionnairePage.time")}
              value={
                values?.time?.split("T")[1]?.split("+")[0] !== "undefined"
                  ? values?.time?.split("T")[1]?.split("+")[0]
                  : t("QuestionnairePage.time")
              }
              onClick={openPopover}
            ></input>
            <Select
              name="weekday"
              placeholder={t("QuestionnairePage.weekday")}
              onChange={handleChange("weekday")}
              onBlur={handleBlur("weekday")}
              data={weekdays}
              defaultValue={userData?.reminder?.split("-")[0]}
            />
            <IonPopover
              ref={popover}
              isOpen={popoverOpen}
              onDidDismiss={() => setPopoverOpen(false)}
            >
              <IonDatetime
                ref={timeRef}
                name="time"
                presentation="time"
                onIonChange={handleChange}
                onBlur={handleBlur}
              ></IonDatetime>
            </IonPopover>
          </div>
        )}
        <PrimaryButton text={t("QuestionnairePage.button")} color="#088179" />
        {loading && <Loader />}
      </form>
    </Menu>
  );
};
