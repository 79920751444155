import { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";

export const Filters = ({ filter, setFilter, initial }) => {
  const { t } = useTranslation();

  const doshas = [
    {
      name: "Vata",
      value: "vata",
    },
    {
      name: "Pitta",
      value: "pitta",
    },
    {
      name: "Kapha",
      value: "kapha",
    },
  ];
  const [categories, setCategories] = useState<any>([]);
  const getCategory = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_RECIPES_CATEGORIES
    ) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES_CATEGORIES,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setCategories(
            res.data.map((item: any) => ({
              name: item.names,
              value: item.id,
            }))
          );
        }
      });
    }
  };
  const [diets, setDiets] = useState<any>([]);
  const getDiets = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_USER_DIETS) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_DIETS,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();

          setDiets(
            res.data.map((item: any) => ({
              name: item.name,
              value: item.id,
            }))
          );
        }
      });
    }
  };
  useEffect(() => {
    getCategory();
    getDiets();
  }, []);
  const [open, setOpen] = useState(false);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form: any = document.querySelector("form");
    const dosha: any = Array.from(
      form.querySelectorAll("input[name=dosha]:checked")
    ).map((item: any) => `&criteria[dosha][]=${item.value}`);
    const category: any = Array.from(
      form.querySelectorAll("input[name=category]:checked")
    ).map((item: any) => `&criteria[category][]=${item.value}`);
    const diet: any = Array.from(
      form.querySelectorAll("input[name=diet]:checked")
    ).map((item: any) => `&criteria[attribute][]=${item.value}`);
    setFilter(dosha.join("") + category.join("") + diet.join("") || null);
    setOpen(false);
  };
  useEffect(() => {
    if (open) {
      document
        ?.querySelector("#content-container")
        ?.classList?.add("menu-open");
    } else {
      document
        ?.querySelector("#content-container")
        ?.classList?.remove("menu-open");
    }
  }, [open]);
  return (
    <>
      <div
        className={`${styles.filtersWrapper} ${!open && styles.filtersHide}`}
      >
        <form className={styles.filters} onSubmit={handleSubmit}>
          <div className={styles.filtersFilter}>
            <p className={styles.filtersFilterHeader}>
              {t("RecipesListingPage.filter.dosha")}
            </p>
            <div className={styles.filtersFilterItems}>
              {doshas.map((dosha) => (
                <div className={styles.filtersFilterItemsItem}>
                  <input
                    className={styles.filtersFilterItemsItemInput}
                    type="checkbox"
                    id={dosha.value}
                    name="dosha"
                    value={dosha.value}
                  />
                  <label
                    htmlFor={dosha.value}
                    className={styles.filtersFilterItemsItemLabel}
                  >
                    {dosha.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.filtersFilter}>
            <p className={styles.filtersFilterHeader}>
              {t("RecipesListingPage.filter.category")}
            </p>
            <div className={styles.filtersFilterItems}>
              {categories.map((category) => (
                <div className={styles.filtersFilterItemsItem}>
                  <input
                    className={styles.filtersFilterItemsItemInput}
                    type="checkbox"
                    id={category.value}
                    name="category"
                    value={category.value}
                    defaultChecked={initial === category.value}
                  />
                  <label
                    htmlFor={category.value}
                    className={styles.filtersFilterItemsItemLabel}
                  >
                    {category.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.filtersFilter}>
            <p className={styles.filtersFilterHeader}>
              {t("RecipesListingPage.filter.diet")}
            </p>
            <div className={styles.filtersFilterItems}>
              {diets.map((diet) => (
                <div className={styles.filtersFilterItemsItem}>
                  <input
                    className={styles.filtersFilterItemsItemInput}
                    type="checkbox"
                    id={diet.value}
                    name="diet"
                    value={diet.value}
                  />
                  <label
                    htmlFor={diet.value}
                    className={styles.filtersFilterItemsItemLabel}
                  >
                    {diet.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <button className={styles.filtersButton}>
            {t("RecipesListingPage.filter.btn")}
          </button>
        </form>
      </div>

      <button
        className={styles.filtersOpenBtn}
        onClick={() => setOpen(!open)}
      />
    </>
  );
};
