import React, { useEffect, useState } from "react";
import { IonItem, IonList, IonSearchbar } from "@ionic/react";
import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { BottomBlobRecipes } from "../../components/BottomBlobRecipes";
import { DebounceInput } from "react-debounce-input";
import { RecipeSlider } from "../../components/RecipeSlider";
import { ReactSVG } from "react-svg";
import { useHistory, useLocation } from "react-router";
import { like } from "../../helpers/like";
import { Loader } from "../../components/Loader";

export const RecipePage = () => {
  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const { t } = useTranslation();
  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState(1);
  const [load, setLoad] = useState(false);

  //---  GET USER DATA
  const getUserData = async () => {
    setLoad(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_USER_READ) {
      await fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_READ,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setCount(res.data.servings);
        }
      });
    }
    setLoad(false);
  };
  //---
  const getData = async () => {
    setLoad(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_RECIPES_DETAIL) {
      await fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES_DETAIL +
          `?id=${query.get("id")?.toString()}`,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          res.data.id = query.get("id");
          setData(res.data);
        }
      });
    }
    setLoad(false);
  };
  useEffect(() => {
    if (query.get("id")) {
      getUserData();
      getData();
    }
  }, [query.get("id")]);
  const createPlan = async (recipeId, date) => {
    setLoad(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append("recipeId", recipeId);
    formdata.append("date", date);
    formdata.append("servings", count.toString());

    const requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_RECIPES_PLAN_CREATE
    ) {
      await fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES_PLAN_CREATE,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          localStorage.removeItem("recipeDate");
          const res = await response.json();
          history.push(`/weeklyplan?showSuccess=true`);
          history.go(0);
        }
      });
    }
    setLoad(false);
  };
  return (
    <Menu title={data?.title || t("RecipePage.title")}>
      {data.media && (
        <div className={styles.recipesSlider}>
          <div className={styles.recipesSliderDoshas}>
            {data?.dosha?.split(",")?.map((item: any, index: number) => (
              <div
                className={styles.recipesSliderDoshasDosha}
                style={{
                  backgroundColor:
                    item === "kapha"
                      ? "#088179"
                      : item === "pitta"
                      ? "#e24a49"
                      : item === "vata"
                      ? "#94a3cb"
                      : "transparent",
                }}
              >
                <ReactSVG
                  src={`/assets/icon/dosha${
                    item.charAt(0).toUpperCase() + item.slice(1)
                  }.svg`}
                />
                <p>{item.charAt(0).toUpperCase() + item.slice(1)}</p>
              </div>
            ))}
          </div>
          <div
            className={styles.recipesLove}
            onClick={async () =>
              setData({
                ...data,
                isFavorite: (await like(data.id))
                  ? !data.isFavorite
                  : data.isFavorite,
              })
            }
          >
            {data.isFavorite ? (
              <ReactSVG src="/assets/icon/love1.svg" />
            ) : (
              <ReactSVG src="/assets/icon/love0.svg" />
            )}
          </div>
          <RecipeSlider data={data.media} />
        </div>
      )}
      <div className={styles.recipes}>
        <div className={styles.recipesHeader}>
          <div className={styles.recipesHeaderBox}>
            <h1 className={styles.recipesHeaderBoxTitle}>{data.title}</h1>
            <p className={styles.recipesHeaderBoxTime}>
              {t("RecipePage.time")}: {data.totalTime}
            </p>
          </div>
          <button
            className={styles.recipesHeaderBtn}
            onClick={() => {
              if (localStorage.getItem("recipeDate")) {
                createPlan(query.get("id"), localStorage.getItem("recipeDate"));
              } else {
                history.push(
                  `/selectday?recipeId=${query.get("id")}&servings=${count}`
                );
              }
            }}
          >
            <ReactSVG src="/assets/icon/calendar.svg" />
          </button>
        </div>
        <div className={styles.recipesServings}>
          <p className={styles.recipesServingsHeader}>
            {t("RecipePage.title")}
          </p>
          <div className={styles.recipesServingsBtns}>
            <button
              className={styles.recipesServingsBtnsBtn}
              onClick={() => count - 1 >= 1 && setCount(count - 1)}
            >
              <ReactSVG src="/assets/icon/minus.svg" />
            </button>
            <p className={styles.recipesServingsBtnsCount}>{count}</p>
            <button
              className={styles.recipesServingsBtnsBtn}
              onClick={() => setCount(count + 1)}
            >
              <ReactSVG src="/assets/icon/plus.svg" />
            </button>
          </div>
        </div>
        <div className={styles.recipesIngredients}>
          <h2 className={styles.recipesIngredientsHeader}>
            {t("RecipePage.need")}:
          </h2>
          <ul className={styles.recipesIngredientsList}>
            {data?.ingredients?.map((item: any, index: number) => (
              <li className={styles.recipesIngredientsListItem}>{`${(
                item.count * count
              )
                ?.toFixed(2)
                ?.replace(".00", "")} ${
                item.count * count <= 1 ? item.unitSingular : item.unitPlural
              } ${item.name}`}</li>
            ))}
          </ul>
        </div>
        <div className={styles.recipesInfo}>
          <p>{t("RecipePage.info")}</p>
          <hr />
        </div>
        <div
          className={styles.recipesDescription}
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
        {data?.notes && (
          <div
            className={styles.recipesNotes}
            dangerouslySetInnerHTML={{
              __html:
                `<p style="color: #f8b857; margin-bottom: 8px; font-weight:bold;">${t(
                  "RecipePage.notes"
                )}</p>` + data.notes,
            }}
          />
        )}
        {data?.additionalInformation && (
          <>
            <p className={styles.recipesAdditionalInformationHeader}>
              {t("RecipePage.additional")}
            </p>
            <div
              className={styles.recipesAdditionalInformation}
              dangerouslySetInnerHTML={{ __html: data.additionalInformation }}
            />
          </>
        )}
      </div>
      <BottomBlobRecipes />
      {load && <Loader />}
    </Menu>
  );
};
