import styles from "./style.module.scss";
import { InfinitySpin } from "react-loader-spinner";

export const Loader = () => {
  return (
    <div className={styles.loader}>
      <InfinitySpin color="#115845" />
    </div>
  );
};
