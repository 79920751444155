import { ReactSVG } from "react-svg";
import styles from "./style.module.scss";
import FlipPage from "react-flip-page";
import { useEffect, useRef, useState } from "react";

export const RecipeSlider = ({ data }: any) => {
  const [length, setLength] = useState(0);
  useEffect(() => {
    if (data) setLength(data.length);
  }, [data]);
  const flipPageRef: any = useRef();
  const [position, setPosition] = useState(0);
  return (
    <div className={styles.Container}>
      {data.length > 1 && (
        <>
          <button
            className={`${styles.ContainerBtn} ${
              !(position < length - 1) && styles.ContainerBtnHide
            }`}
            onClick={() => {
              setPosition(position + 1);
              flipPageRef.current.gotoNextPage();
            }}
          >
            <ReactSVG src="assets/icon/right.svg" />
          </button>
          <button
            className={`${styles.ContainerBtn} ${
              !(position >= 1) && styles.ContainerBtnHide
            }`}
            onClick={() => {
              setPosition(position - 1);
              flipPageRef.current.gotoPreviousPage();
            }}
          >
            <ReactSVG src="assets/icon/right.svg" />
          </button>
        </>
      )}
      <FlipPage
        ref={flipPageRef}
        orientation="horizontal"
        className={styles.FlipPage}
        disableSwipe={true}
      >
        {data.map((item: any, index: number) => (
          <div
            className={styles.Item}
            style={{ backgroundImage: `url(${item})` }}
          />
        ))}
      </FlipPage>
    </div>
  );
};
