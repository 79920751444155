import {
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";

export const Select = ({
  name,
  placeholder,
  onChange,
  onBlur,
  data,
  multiple,
  defaultValue,
  onlyLabel,
}: {
  name: string;
  placeholder: string;
  onChange?: any;
  onBlur?: any;
  data: any;
  multiple?: boolean;
  defaultValue?: any;
  onlyLabel?: boolean;
}) => {
  const selectRef = useRef<HTMLIonSelectElement>(null);
  useEffect(() => {
    if (selectRef.current && defaultValue) {
      selectRef.current.value = defaultValue;
    }
  }, [defaultValue]);

  return (
    <IonList className={styles.ionList}>
      {onlyLabel && (
        <IonLabel className={styles.ionLabel}>{placeholder}</IonLabel>
      )}
      <IonItem className={styles.ionItem}>
        <IonSelect
          ref={selectRef}
          onIonChange={onChange}
          multiple={multiple}
          className={`${styles.ionSelect} ${
            onlyLabel && styles.ionSelectOnlyLabel
          }`}
          name={name}
          placeholder={onlyLabel ? "" : placeholder}
          onBlur={onBlur}
        >
          {data.map((item: any) => (
            <IonSelectOption value={item.value}>{item.label}</IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    </IonList>
  );
};
