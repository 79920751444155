import styles from "./style.module.scss";
import {useEffect, useState} from "react";
import {log} from "util";
export const BottomBlob = () => {

  const [src, setSrc] = useState<string>("")
  const [style, setStyle] = useState<string>("")

  const blobs = [
    {name: "blue", src: "assets/blobs/vata.png", class: "blob"},
    {name: "yellow", src: "assets/blobs/generic.png", class: "blob__yellow"},
    {name: "green", src: "assets/blobs/kapha.png", class: "blob__green"},
    {name: "red", src: "assets/blobs/pitta.png", class: "blob__red"},
  ]

  function setRandomBlobSrc(): void {
    const randomIndex = Math.floor(Math.random() * blobs.length);
    const randomBlob = blobs[randomIndex];
    setSrc(randomBlob.src);
    setStyle(randomBlob.class);
  }

  useEffect(() => setRandomBlobSrc, []);

  return (
    <img
      className={styles[style]}
      src={src}
      alt=""
    />
  );
};
