import { IonContent, IonPage } from "@ionic/react";
import { ErrorLabel } from "../../components/ErrorLabel";
import { Input } from "../../components/Input";
import { PrimaryButton } from "../../components/PrimaryButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./style.module.scss";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { SafeArea } from "capacitor-plugin-safe-area";

export const LoginPage = () => {
  const [invalidCredentials, setInvalidCredentials] = useState("");
  const { t } = useTranslation();
  const history = useHistory();
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("LoginPage.schema.email"))
      .required(t("LoginPage.schema.required")),
    password: Yup.string().required(t("LoginPage.schema.required")),
  });

  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      validationSchema: LoginSchema,
      initialValues: {
        email: "",
        password: "",
      },
      onSubmit: async (values: any) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const formdata = JSON.stringify({
          email: values.email,
          password: values.password,
        });

        const requestOptions: any = {
          headers: myHeaders,
          method: "POST",
          body: formdata,
          redirect: "follow",
          referrerPolicy: "no-referrer-when-downgrade",
        };
        if (process.env.REACT_APP_API_URL && process.env.REACT_APP_USER_LOGIN) {
          fetch(
            process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_LOGIN,
            requestOptions
          ).then(async (response) => {
            if (response.status === 200) {
              const res = await response.json();
              localStorage.setItem("token", res.token);
              const x = localStorage.getItem("newUser");
              if (x === "true") {
                history.push("/questionnaire");
                history.go(0);
              } else {
                history.push("/dashboard");
                history.go(0);
              }
            } else if (response.status === 401) {
              setInvalidCredentials(t("LoginPage.form.error.401"));
            } else if (response.status === 404) {
              setInvalidCredentials(t("LoginPage.form.error.404"));
            } else {
              setInvalidCredentials(t("LoginPage.form.error.other"));
            }
          });
        }
      },
    }
  );
  const [statusBarHeight, setStatusBarHeight] = useState<any>(0);
  useEffect(() => {
    if (Capacitor.getPlatform() === "ios") {
      SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
        setStatusBarHeight(statusBarHeight);
      });
    }
  }, []);
  const test = async () => {
    const x = await fetch("https://swapi.dev/api/people/1/");
    console.log(x);
  };
  return (
    <IonPage id="main-content">
      <div
        style={{
          width: "100%",
          height: `${statusBarHeight}px`,
          backgroundColor: "#000",
        }}
      />
      <IonContent>
        <div className={styles.wrapper}>
          <div className={styles.login}>
            <img
              className={styles.loginLogo}
              src="/assets/images/logo.png"
              alt="logo"
            />
            <p className={styles.loginSubtitle}>{t("LoginPage.subtitle")}</p>
            <img
              className={styles.loginImage}
              src="/assets/images/head.webp"
              alt="img"
              onClick={test}
            />
            <form onSubmit={handleSubmit} className={styles.loginForm}>
              <Input
                login
                type="email"
                name="email"
                placeholder={t("LoginPage.form.email")}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorLabel error={errors.email} touched={touched.email} />
              <Input
                login
                name="password"
                type="password"
                placeholder={t("LoginPage.form.password")}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorLabel error={errors.password} touched={touched.password} />
              <ErrorLabel
                error={invalidCredentials}
                touched={invalidCredentials}
              />
              <a className={styles.loginForgot} href="/resetpassword">
                {t("LoginPage.form.forgot")}
              </a>
              <PrimaryButton text={t("LoginPage.form.login")} />
            </form>
            <p className={styles.loginLink}>
              {t("LoginPage.form.new")}{" "}
              <a href="/register">{t("LoginPage.form.spice")}</a>
            </p>
          </div>
          <img
            className={styles.loginBlob}
            src="assets/backgrounds/blue_background.svg"
            alt=""
          />
        </div>
      </IonContent>
    </IonPage>
  );
};
