export const like = async (id: number) => {
  let result = false;
  const myHeaders = new Headers();
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions: any = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  if (process.env.REACT_APP_API_URL && process.env.REACT_APP_FAVORITE_CREATE) {
    await fetch(
      process.env.REACT_APP_API_URL +
        process.env.REACT_APP_FAVORITE_CREATE +
        `?id=${id}`,
      requestOptions
    ).then(async (response) => {
      if (response.status === 200) {
        const res = await response.json();
        result = true;
      }
    });
  }
  return result;
};
