import { useState } from "react";
import styles from "./style.module.scss";

export const Accordion = ({ title, children, icon }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className={`${styles.accordion}`}>
      <div
        className={`${styles.accordionTitle} ${
          isOpen && styles.accordionTitleOpen
        }
        ${icon && styles.accordionTitleImg}
        `}
        onClick={toggle}
      >
        {icon && <img className={styles.accordionIcon} src={icon} />}
        <p className={styles.accordionHeader}>{title}</p>
      </div>
      {isOpen && <div className={styles.accordionContent}>{children}</div>}
    </div>
  );
};
