export const checkIsTommorow = (date: Date) => {
  const today = new Date();
  const tommorow = new Date(today);
  tommorow.setDate(tommorow.getDate() + 1);

  return (
    date.getDate() === tommorow.getDate() &&
    date.getMonth() === tommorow.getMonth() &&
    date.getFullYear() === tommorow.getFullYear()
  );
};
export const checkIsToday = (date: Date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};
export const checkIsYesterday = (date: Date) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  );
};
