import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ReactSVG } from "react-svg";
import styles from "./style.module.scss";
export const AddIngredient = () => {
  const history = useHistory();
  const [name, setName] = useState("");
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append("name", name);

    const requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_INGREDIENTLIST_CREATE
    ) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_INGREDIENTLIST_CREATE,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setTimeout(() => {
            history.push("/ingredientlist");
            history.go(0);
          }, 100);
        }
      });
    }
  };
  const { t } = useTranslation();
  return (
    <div className={styles.addIngredientWrapper}>
      <form className={styles.addIngredient} onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder={t("Components.AddIngredient.placeholder")}
          onChange={(e) => setName(e.target.value)}
        />
        <button className={styles.addIngredientButton} type="submit">
          {t("Components.AddIngredient.add")}
        </button>
      </form>
    </div>
  );
};
