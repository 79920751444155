import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ReactSVG } from "react-svg";
import { like } from "../../helpers/like";
import styles from "./style.module.scss";

export const ModalPencil = ({ open, setOpen, id, delId, setRefresh }: any) => {
  const [del, setDel] = useState(false);
  const deletePlan = async (id: any) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append("recipePlanId", id);

    const requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_RECIPES_PLAN_DELETE
    ) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES_PLAN_DELETE,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setRefresh(Date.now());
        }
      });
    }
  };

  const history = useHistory();
  const { t } = useTranslation();
  return open ? (
    <>
      {!del ? (
        <div className={styles.ModalPencil}>
          <ReactSVG
            className={styles.ModalPencilExit}
            src="/assets/icon/plus.svg"
            onClick={() => {
              setOpen(false);
            }}
          />
          <div className={styles.ModalPencilTiles}>
            <div
              className={styles.ModalPencilTilesTile}
              onClick={() => {
                setOpen(false);
                history.push(`/recipe?id=${id}`);
              }}
            >
              <ReactSVG src="/assets/icon/menu2.svg" />
              <p>{t("Components.ModalPencil.recipe")}</p>
            </div>
            <div
              className={styles.ModalPencilTilesTile}
              onClick={() => {
                setOpen(false);
                history.push(`/selectday?recipeId=${id}&recipePlanId=${delId}`);
                history.go(0);
              }}
            >
              <ReactSVG src="/assets/icon/edit_day.svg" />
              <p>{t("Components.ModalPencil.day")}</p>
            </div>
            <div
              className={styles.ModalPencilTilesTile}
              onClick={() => {
                setOpen(false);
                like(id);
                setRefresh(Date.now());
              }}
            >
              <ReactSVG src="/assets/icon/menu1.svg" />
              <p>{t("Components.ModalPencil.like")}</p>
            </div>
            <div
              className={styles.ModalPencilTilesTile}
              onClick={() => {
                setDel(true);
              }}
            >
              <ReactSVG src="/assets/icon/delete.svg" />
              <p>{t("Components.ModalPencil.remove")}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.ModalPencil}>
          <ReactSVG
            className={styles.ModalPencilExit}
            src="/assets/icon/plus.svg"
            onClick={() => {
              setDel(false);
              setOpen(false);
            }}
          />
          <div className={styles.ModalPencilDelModal}>
            <p className={styles.ModalPencilDelModalHeader}>
              {t("ModalPencil.title")}
            </p>
            <p className={styles.ModalPencilDelModalDescription}>
              {t("ModalPencil.description")}
            </p>
            <div className={styles.ModalPencilDelModalBtns}>
              <button
                className={styles.ModalPencilDelModalBtnsBtn}
                onClick={() => {
                  setDel(false);
                  setOpen(false);
                }}
              >
                {t("ModalPencil.cancel")}
              </button>
              <button
                className={styles.ModalPencilDelModalBtnsBtn}
                onClick={() => {
                  setOpen(false);
                  deletePlan(delId);
                  setRefresh(Date.now());
                }}
              >
                {t("ModalPencil.ok")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  ) : null;
};
