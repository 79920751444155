import { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { Menu } from "../../components/Menu";
import { useTranslation } from "react-i18next";
import { BottomBlobRecipes } from "../../components/BottomBlobRecipes";
import { useHistory, useLocation } from "react-router";
import { Capacitor } from "@capacitor/core";
import { IonRefresher, IonRefresherContent } from "@ionic/react";

export const PaymentPage = () => {
  const [isNative, setIsNative] = useState<boolean>(
    Capacitor.isNativePlatform()
  );
  const [code, setCode] = useState<string>("");
  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const { t } = useTranslation();
  //---
  const sendData = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const body = new FormData();
    body.append("id", id);
    const requestOptions: any = {
      headers: myHeaders,
      method: "POST",
      redirect: "follow",
      body,
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_PAYMENT_UPDATE) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_PAYMENT_UPDATE,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
        }
        history.push("/payment");
        history.go(0);
      });
    }
  };
  const [id, setId] = useState<any>(query.get("id"));
  useEffect(() => {
    if (id) sendData();
  }, [id]);

  //---
  const [subscriptionList, setSubscriptionList] = useState<any>([]);
  const getSubscriptionList = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_SUBSCRIPTIONS) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_SUBSCRIPTIONS,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setSubscriptionList(res.subscriptions);
        }
      });
    }
  };
  //---
  const [historyList, setHistoryList] = useState<any>([]);
  const [checked, setChecked] = useState<any>(null);
  const getHistory = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_PAYMENT_READ) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_PAYMENT_READ,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setHistoryList(res.subscriptions);
          setChecked(
            res.subscriptions.filter((item: any) => item.isValid)[0]
              ?.subscriptionId || null
          );
        }
      });
    }
  };
  useEffect(() => {
    getSubscriptionList();
    getHistory();
  }, []);

  const handleCode = (e: any) => {
    e.preventDefault();
    const subscriptionId =
      document?.querySelector("form")?.subscriptionValue?.value || "";
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const formdata = new FormData();

    formdata.append("code", code);
    formdata.append("subscriptionId", subscriptionId);
    const requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_PAYMENT_DISCOUNT
    ) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_PAYMENT_DISCOUNT,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setSummary(res.price < 0 ? 0 : res.price);
          setCodeValid(code);
        } else {
          setCodeValid(false);
          if (checkedValue) {
            const item = subscriptionList.filter(
              (item: any) => item.id === checkedValue
            )[0];
            setSummary(item.cost < 0 ? 0 : item.cost);
          }
        }
      });
    }
  };
  const handleSubscription = (e: any) => {
    e.preventDefault();
    if (isNative && process.env.REACT_APP_APP_URL) {
      window.location.href = process.env.REACT_APP_APP_URL;
      return;
    }
    if (summary === 0) {
      setCodeValid(false);
      return;
    }
    const subscriptionId = e.target.subscriptionValue.value;
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append("subscriptionId", subscriptionId);
    if (codeValid) {
      formdata.append("code", codeValid);
    }
    const requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_PAYMENT_CREATE) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_PAYMENT_CREATE,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          if (res.url === null) {
            setCodeValid(false);
            return;
          }
          window.location.href = res.url;
        }
      });
    }
  };
  const handleRefresh = (e: any) => {
    getSubscriptionList();
    getHistory();
    e.detail.complete();
  };
  const [checkedValue, setCheckedValue] = useState<any>(null);
  const [summary, setSummary] = useState<any>(0);
  const [codeValid, setCodeValid] = useState<any>(null);
  useEffect(() => {
    if (checkedValue) {
      const item = subscriptionList.filter(
        (item: any) => item.id === checkedValue
      )[0];
      setSummary(item.cost);
      setCodeValid(null);
    }
  }, [checkedValue]);
  return (
    <Menu title={t("PaymentPage.title")} goToMenu>
      <div className={styles.payment}>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <form onSubmit={handleSubscription}>
          <div className={styles.paymentSubList}>
            {!isNative &&
              subscriptionList.map((item: any, index: number) => (
                <label
                  key={index}
                  htmlFor={item.id}
                  className={styles.paymentSubListItem}
                >
                  <input
                    className={styles.paymentSubListItemRadio}
                    type="radio"
                    value={item.id}
                    name="subscriptionValue"
                    id={item.id}
                    defaultChecked={checked === item.id}
                    disabled={checked !== null}
                    required
                    onChange={(e) => {
                      setCheckedValue(e.target.value);
                    }}
                  />
                  <label
                    htmlFor={item.id}
                    className={styles.paymentSubListItemLabel}
                  >
                    {item.months}{" "}
                    {item.months <= 1
                      ? t("PaymentPage.month")
                      : t("PaymentPage.months")}{" "}
                    ({item.cost} €)
                  </label>
                </label>
              ))}
          </div>
          <div className={styles.paymentBuyBtnWrap}>
            {checkedValue && (
              <div className={styles.paymentBuyBtnWrapCodeWrap}>
                <div className={styles.paymentBuyBtnWrapCode}>
                  <input
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    placeholder={t("PaymentPage.codePlaceholder")}
                  />
                  <button onClick={handleCode}>ok</button>
                </div>
                {codeValid === false && (
                  <p className={styles.paymentBuyBtnWrapCodeWrapError}>
                    {summary <= 0
                      ? t("PaymentPage.codeError2")
                      : t("PaymentPage.codeError")}
                  </p>
                )}
              </div>
            )}
            {checkedValue && (
              <p className={styles.paymentBuyBtnWrapSummary}>
                {t("PaymentPage.summary")}: {summary} Euro
              </p>
            )}
            <button
              className={styles.paymentBuyBtn}
              disabled={checked !== null}
            >
              {checked !== null
                ? `${historyList.sort((item) => item.isValid)[0]?.dayCount} ${t(
                    "PaymentPage.days"
                  )}`
                : t("PaymentPage.button")}
            </button>
          </div>
        </form>
        <div>
          <p className={styles.paymentHistoryHeader}>
            {t("PaymentPage.history")}
          </p>
          <div className={styles.paymentHistory}>
            {historyList.map((item: any, index: number) => (
              <div key={item.id} className={styles.paymentHistoryItem}>
                <div className={styles.paymentHistoryItemDescription}>
                  <p>
                    {item.months} {t("PaymentPage.subscription")}
                  </p>
                  <p>
                    {t("PaymentPage.expires")} {item.validDate}
                  </p>
                </div>
                <div className={styles.paymentHistoryItemCost}>
                  <p>{item.paidCost} €</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <BottomBlobRecipes />
    </Menu>
  );
};
