import { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import styles from "./style.module.scss";

export const Search = () => {
  const history = useHistory();
  const [openSearch, setOpenSearch] = useState(false);
  const [value, setValue] = useState("");

  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [numItemsPerPage, setNumItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();

  const { t } = useTranslation();
  const getData = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_RECIPES) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES +
          `?page=${page}&phrase=${value}`,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setTotalCount(res.data.totalCount);
          setNumItemsPerPage(res.data.numItemsPerPage);
          setData([...res.data.items.ingredients, ...res.data.items.recipes]);
        }
      });
    }
  };

  useEffect(() => {
    if (value.length > 2) getData();
  }, [value]);
  return (
    <div className={styles.Search}>
      <DebounceInput
        placeholder={t("Search Spic'it")}
        className={styles.SearchInput}
        minLength={2}
        debounceTimeout={300}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        onFocus={() => setOpenSearch(true)}
        onBlur={() => setTimeout(() => setOpenSearch(false), 500)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            history.push(
              `/recipeslisting?phrase=${value}&&categoryName=${value}`
            );
            history.go(0);
          }
        }}
      />
      {openSearch && value.length > 2 && (
        <ul>
          {data.map((d: any, key: number) => (
            <a
              key={key}
              href={
                d.title
                  ? `/recipe?id=${d.id}`
                  : d.title
                  ? `/recipeslisting?categoryId=${d.id}&&categoryName=${d.name}`
                  : `/recipeslisting?id=${d.id}&&categoryName=${d.name}`
              }
            >
              <li>{d.title || d.name}</li>
            </a>
          ))}
        </ul>
      )}
      <img
        className={styles.SearchIcon}
        src="/assets/icon/search.svg"
        alt="search"
        onClick={() => {
          history.push(
            `/recipeslisting?phrase=${value}&&categoryName=${value}`
          );
          history.go(0);
        }}
      />
    </div>
  );
};
