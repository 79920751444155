import styles from "./style.module.scss";
import { Menu } from "../../components/Menu";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { Calendar } from "react-multi-date-picker";
import { addLeadingZero } from "../../helpers/addLeadingZero";
import { datesBetween } from "../../helpers/datesBetween";
import { AddIngredient } from "../../components/AddIngredient";

export const IngredientAddPage = () => {
  const { t } = useTranslation();
  return (
    <Menu title={t("IngredientListPage.add")}>
      <AddIngredient />
    </Menu>
  );
};
