import { useCallback, useEffect, useRef } from "react";
import { ReactSVG } from "react-svg";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { like } from "../../helpers/like";

const Column = ({ current, data, refresh, setRefresh }: any) => {
  const { t } = useTranslation();

  const week = [
    t("weekday.sun"),
    t("weekday.mon"),
    t("weekday.tue"),
    t("weekday.wed"),
    t("weekday.thu"),
    t("weekday.fri"),
    t("weekday.sat"),
  ];
  const date = new Date(data.day.split("-").reverse().join("-"));
  const day = date.getDate();
  const weekday = date.getDay();
  return (
    <div className={`${styles.Column} ${current && styles.ColumnCurrent}`}>
      <div className={styles.ColumnHeader}>
        <div className={styles.ColumnDay}>{week[weekday]}</div>
        <div className={styles.ColumnDay}>{day}</div>
      </div>
      <div className={styles.ColumnContent}>
        {data.data.map((item: any) => (
          <Item data={item.recipe} refresh={refresh} setRefresh={setRefresh} />
        ))}
      </div>
    </div>
  );
};

const Item = ({ data, refresh, setRefresh }: any) => {
  return (
    <div className={styles.Item}>
      <div
        className={styles.ItemLove}
        onClick={async () => {
          await like(data.id);
          setRefresh(!refresh);
        }}
      >
        {data.isFavorite ? (
          <ReactSVG src="/assets/icon/love1.svg" />
        ) : (
          <ReactSVG src="/assets/icon/love0.svg" />
        )}
      </div>
      <a href={`/recipe?id=${data.id}`}>
        <img src={data.media} alt="img" />
        <p>{data.name}</p>
      </a>
    </div>
  );
};

export const WeeklyPlan = ({ data, refresh, setRefresh }: any) => {
  const arr: any = [];
  data.forEach((item: any) => {
    if (arr.find((i: any) => i.day === item.day)) {
      arr.find((i: any) => i.day === item.day).data.push(item);
      return;
    } else {
      arr.push({
        day: item.day,
        data: [item],
      });
    }
  });

  return (
    <div className={styles.Container}>
      {arr.map((item: any, index: number) => (
        <Column
          current={item.data[0].today}
          data={item}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ))}
    </div>
  );
};
