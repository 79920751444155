import { Capacitor } from "@capacitor/core";
import { IonContent } from "@ionic/react";
import { SafeArea } from "capacitor-plugin-safe-area";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { ReactSVG } from "react-svg";
import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";

export const RodoPage = () => {
  const token = localStorage.getItem("token");
  const history = useHistory();
  const { t } = useTranslation();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const [id, setId] = useState<any>(query.get("id"));
  const [description, setDescription] = useState<any>("");
  const getPosts = () => {
    const myHeaders = new Headers();
    if (token) myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (
      process.env.REACT_APP_API_URL &&
      process.env.REACT_APP_CMS_POSTS_TERMS
    ) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_CMS_POSTS_RODO,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setDescription(res.data);
        }
      });
    }
  };
  useEffect(() => {
    getPosts();
  }, []);
  const [statusBarHeight, setStatusBarHeight] = useState<any>(0);
  useEffect(() => {
    if (Capacitor.getPlatform() === "ios") {
      SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
        setStatusBarHeight(statusBarHeight);
      });
    }
  }, []);
  return !token ? (
    <IonContent>
      <div
        style={{
          width: "100%",
          height: `${statusBarHeight}px`,
          backgroundColor: "#000",
        }}
      />
      <div style={{ position: "relative", minHeight: "100vh" }}>
        <div className={styles.cms}>
          <ReactSVG
            className={styles.cmsBack}
            src="assets/icon/left.svg"
            onClick={() => {
              history.goBack();
            }}
          />
          <h1 style={{ textAlign: "left" }}>{description.title}</h1>
          <div
            className={styles.cmsContent}
            dangerouslySetInnerHTML={{ __html: description.content }}
          />
        </div>
      </div>
    </IonContent>
  ) : (
    <Menu title={t("Menu.items.8")} other goToMenu>
      <div
        style={{
          width: "100%",
          height: `${statusBarHeight}px`,
          backgroundColor: "#000",
        }}
      />
      <div style={{ position: "relative", minHeight: "100vh" }}>
        <div className={styles.cms}>
          <div
            className={styles.cmsContent}
            dangerouslySetInnerHTML={{ __html: description.content }}
          />
        </div>
      </div>
    </Menu>
  );
};
