import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonDatetime,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonList,
  IonSearchbar,
} from "@ionic/react";
import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { BottomBlob } from "../../components/BottomBlob";
import { DebounceInput } from "react-debounce-input";
import { ReactSVG } from "react-svg";
import { useLocation } from "react-router-dom";
import { Search } from "../../components/Search";
import { like } from "../../helpers/like";
import { WeeklyPlanEdit } from "../../components/WeeklyPlanEdit";
import { WeeklyPlanVertical } from "../../components/WeeklyPlanVertical";
import {
  checkIsToday,
  checkIsTommorow,
  checkIsYesterday,
} from "../../helpers/time";
import { ModalPencil } from "../../components/ModalPencil";
import { Calendar } from "react-multi-date-picker";
import { addLeadingZero } from "../../helpers/addLeadingZero";
import { createContext } from "react";
import { WeeklyPlanSelect } from "../../components/WeeklyPlanSelect";
import { PrimaryButton } from "../../components/PrimaryButton";
import { datesBetween } from "../../helpers/datesBetween";

export const SelectDayPage = () => {
  const { t } = useTranslation();
  const [view, setView] = useState(0);
  const [planRecipes, setPlanRecipes] = useState([]);
  const getPlanRecipes = (fromDate?: string, toDate?: string) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const x =
      !!fromDate && !!toDate ? `?fromDate=${fromDate}&toDate=${toDate}` : "";
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_RECIPES_PLAN) {
      fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_RECIPES_PLAN + x,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          res.data.plan.map((item: any) => {
            const day = new Date(item.day.split("-").reverse().join("-"));
            if (checkIsToday(day)) {
              item.dayTitle = t("DashboardPage.today");
              item.today = true;
            } else if (checkIsTommorow(day)) {
              item.dayTitle = t("DashboardPage.tomorrow");
            } else if (checkIsYesterday(day)) {
              item.dayTitle = t("DashboardPage.yesterday");
            } else {
              item.dayTitle = item.day.split("-").reverse().join("-");
            }
          });
          setPlanRecipes(res.data.plan);
        }
      });
    }
  };
  const [refresh, setRefresh] = useState(false);
  const [values, setValues] = useState<any>({});
  const dzisiaj = new Date();
  const zaTydzien = new Date();
  zaTydzien.setDate(zaTydzien.getDate() + 6);
  const dzisiajFormatted = `${dzisiaj.getDate().toString().padStart(2, "0")}.${(
    dzisiaj.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")} - ${zaTydzien.getDate().toString().padStart(2, "0")}.${(
    zaTydzien.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}`;

  const [displayDate, setDisplayDate] = useState<any>(dzisiajFormatted);
  const [dates, setDates] = useState<any>([]);
  useEffect(() => {
    if (
      values[0]?.day &&
      values[1]?.day &&
      values[0]?.month?.number &&
      values[1]?.month?.number &&
      values[0]?.year &&
      values[1]?.year
    ) {
      const start = `${addLeadingZero(values[0].day)}-${addLeadingZero(
        values[0].month.number
      )}-${values[0].year}`;
      const end = `${addLeadingZero(values[1].day)}-${addLeadingZero(
        values[1].month.number
      )}-${values[1].year}`;
      setDates(datesBetween(start, end));
      getPlanRecipes(start, end);
      getPlanRecipes(start, end);
      setDisplayDate(
        `${addLeadingZero(values[0].day)}.${addLeadingZero(
          values[0].month.number
        )} - ${addLeadingZero(values[1].day)}.${addLeadingZero(
          values[1].month.number
        )}`
      );
    } else {
      const start = `${dzisiaj.getDate().toString().padStart(2, "0")}-${(
        dzisiaj.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${dzisiaj.getFullYear()}`;

      const end = `${zaTydzien.getDate().toString().padStart(2, "0")}-${(
        zaTydzien.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${zaTydzien.getFullYear()}`;

      setDates(datesBetween(start, end));
      getPlanRecipes();
      getPlanRecipes();
    }
  }, [values, refresh]);
  const [calendarOpen, setCalendarOpen] = useState(false);

  return (
    <Menu title={t("SelectDayPage.title")}>
      <div className={styles.weekly}>
        <div className={styles.weeklyTopBar}>
          <div className={styles.weeklyTopBarDate}>
            <p
              className={styles.weeklyTopBarDateLabel}
              onClick={() => setCalendarOpen(!calendarOpen)}
            >
              {displayDate?.replace(/\./g, "/") || t("SelectDayPage.calendar")}
            </p>
            {calendarOpen && (
              <>
                <Calendar
                  className={styles.weeklyTopBarDateCalendar}
                  value={values}
                  onChange={setValues}
                  range
                  rangeHover
                />
                <div
                  className={styles.weeklyTopBarDateCalendarOverlay}
                  onClick={() => setCalendarOpen(!calendarOpen)}
                />
              </>
            )}
          </div>
        </div>
        <div className={styles.dashboardContent}>
          {dates.length && (
            <WeeklyPlanSelect
              data={planRecipes}
              setRefresh={setRefresh}
              dates={dates}
            />
          )}
        </div>
      </div>
    </Menu>
  );
};
