import { useCallback, useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import { like } from "../../helpers/like";
import { ModalPencil } from "../ModalPencil";
import { ModalPlus } from "../ModalPlus";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";

const Column = ({ current, data, setRefresh }: any) => {
  const { t } = useTranslation();

  const week = [
    t("SettingsPage.weekdays.sunday"),
    t("SettingsPage.weekdays.monday"),
    t("SettingsPage.weekdays.tuesday"),
    t("SettingsPage.weekdays.wednesday"),
    t("SettingsPage.weekdays.thursday"),
    t("SettingsPage.weekdays.friday"),
    t("SettingsPage.weekdays.saturday"),
  ];
  const date = new Date(data.day.split("-").reverse().join("-"));
  const day =
    date.getDate() +
    "/" +
    ((date.getMonth() + 1).toString().length === 1
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1);
  const weekday = date.getDay();
  const [open, setOpen] = useState(false);
  return (
    <div className={`${styles.Column} ${current && styles.ColumnCurrent}`}>
      <div className={styles.ColumnHeader}>
        <p className={styles.ColumnDay}>
          {week[weekday]} {day}
        </p>
        <ReactSVG src="/assets/icon/plus.svg" onClick={() => setOpen(true)} />
      </div>
      <div className={styles.ColumnContent}>
        {data.data.map((item: any, index: number) => (
          <Item key={"content" + index} data={item} setRefresh={setRefresh} />
        ))}
      </div>
      <ModalPlus open={open} setOpen={setOpen} data={data} />
    </div>
  );
};

const Item = ({ data, setRefresh }: any) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.Item}>
      <div
        className={styles.ItemLove}
        onClick={() => {
          like(data.recipe.id);
          setRefresh((prev: any) => !prev);
        }}
      >
        {data.recipe.isFavorite ? (
          <ReactSVG src="/assets/icon/love1.svg" />
        ) : (
          <ReactSVG src="/assets/icon/love0.svg" />
        )}
      </div>
      <a href={`/recipe?id=${data.recipe.id}`}>
        <img src={data.recipe.media} alt="img" />
      </a>
      <div className={styles.ItemHeader}>
        <a href={`/recipe?id=${data.recipe.id}`}>
          <p>{data.recipe.name}</p>
        </a>
        <ReactSVG src="/assets/icon/dots.svg" onClick={() => setOpen(true)} />
      </div>
      <ModalPencil
        open={open}
        setOpen={setOpen}
        id={data.recipe.id}
        delId={data.id}
        setRefresh={setRefresh}
      />
    </div>
  );
};

export const WeeklyPlanVertical = ({ data, setRefresh, dates }: any) => {
  const arr: any = [];
  dates.forEach((item: any) => {
    arr.push({
      day: item,
      data: [],
    });
  });
  data.forEach((item: any) => {
    if (arr.find((i: any) => i.day === item.day)) {
      arr.find((i: any) => i.day === item.day).data.push(item);
      return;
    }
  });
  return (
    <div className={styles.Container}>
      {arr.map((item: any, index: number) => (
        <Column
          key={"column" + index}
          current={item.data[0]?.today}
          data={item}
          setRefresh={setRefresh}
        />
      ))}
    </div>
  );
};
