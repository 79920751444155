import { IonContent, IonPage } from "@ionic/react";
import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { PrimaryButton } from "../../components/PrimaryButton";
import { Accordion } from "../../components/Accordion";
import { ReactSVG } from "react-svg";
import { BottomBlobDosha } from "../../components/BottomBlobDosha";
import { useHistory } from "react-router";

export const DoshaTestPage2 = () => {
  const history = useHistory();

  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [stage, setStage] = useState(0);

  const data = [
    // @ts-ignore
    ...t("DoshaTestPage.test.1.questions", { returnObjects: true }),
  ];
  const accordionData = [
    // @ts-ignore
    ...t("DoshaTestPage.test.1.introduction.faq", { returnObjects: true }),
  ];
  const [steps, setSteps] = useState(data.length);
  const [answers, setAnswers] = useState(Array(data.length));

  const check = () => {
    const checkedValue = (
      document.querySelector("input:checked") as HTMLInputElement
    )?.value;
    const arr = answers;
    arr[step] = checkedValue;
    setAnswers(arr);
  };
  const next = () =>
    step + 1 < steps ? setStep(step + 1) : countDosha() && setStage(2);
  const back = () => (step - 1 >= 0 ? setStep(step - 1) : setStage(0));

  const [result, setResult] = useState<any>();
  const [isNone, setIsNone] = useState(false);
  const countDosha = () => {
    const p = answers.filter((a) => a === "P").length;
    const k = answers.filter((a) => a === "K").length;
    const v = answers.filter((a) => a === "V").length;
    const n = answers.filter((a) => a === "N").length;
    if (n === steps) {
      setIsNone(true);
    }
    const arr = [
      {
        name: "Vata",
        value: Math.round((v / (steps - n)) * 100),
        description: t("DoshaTestPage.test.1.summary.vata"),
        img: "/assets/icon/doshaVata.svg",
        color: "#94a3cb",
      },
      {
        name: "Pitta",
        value: Math.round((p / (steps - n)) * 100),
        description: t("DoshaTestPage.test.1.summary.pitta"),
        img: "/assets/icon/doshaPitta.svg",
        color: "#e24949",
      },
      {
        name: "Kapha",
        value: Math.round((k / (steps - n)) * 100),
        description: t("DoshaTestPage.test.1.summary.kapha"),
        img: "/assets/icon/doshaKapha.svg",
        color: "#088179",
      },
    ];
    arr.sort((a, b) => b.value - a.value);
    getLastRecipes(arr[0]?.name.toLowerCase());

    setResult(arr);
    return true;
  };
  const [lastRecipes, setLastRecipes] = useState([]);
  const getLastRecipes = (dsha) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_RECIPES) {
      fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_RECIPES +
          `?criteria[dosha][]=${dsha}`,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setLastRecipes(res?.data?.items?.recipes);
        }
      });
    }
  };
  if (stage === 1) {
    return (
      <Menu title={t("DoshaTestPage.test.1.title")} link="/doshatest2">
        <div className={styles.doshaTest}>
          <div className={styles.progressBox}>
            <div className={styles.steps}>
              {step + 1}/{steps}
            </div>
            <div className={styles.progress}>
              <div
                className={styles.progressBar}
                style={{ width: `calc((100% / ${steps}) * ${step + 1})` }}
              />
            </div>
          </div>
          <div>
            <h2>{data[step].question}</h2>
            <div className={styles.answers}>
              <label htmlFor="a" className={styles.answersAnswer}>
                <input
                  key={1 + step + "inpt"}
                  type="radio"
                  value={data[step].answers[0].value}
                  defaultChecked={answers[step] === data[step].answers[0].value}
                  name="answer"
                  id="a"
                  onChange={check}
                />
                <label htmlFor="a">{data[step].answers[0].answer}</label>
              </label>
              <label htmlFor="b" className={styles.answersAnswer}>
                <input
                  key={2 + step + "inpt"}
                  type="radio"
                  value={data[step].answers[1].value}
                  defaultChecked={answers[step] === data[step].answers[1].value}
                  name="answer"
                  id="b"
                  onChange={check}
                />
                <label htmlFor="b">{data[step].answers[1].answer}</label>
              </label>
              <label htmlFor="c" className={styles.answersAnswer}>
                <input
                  key={3 + step + "inpt"}
                  type="radio"
                  value={data[step].answers[2].value}
                  defaultChecked={answers[step] === data[step].answers[2].value}
                  name="answer"
                  id="c"
                  onChange={check}
                />
                <label htmlFor="c">{data[step].answers[2].answer}</label>
              </label>
              <label htmlFor="d" className={styles.answersAnswer}>
                <input
                  key={4 + step + "inpt"}
                  type="radio"
                  value={data[step].answers[3].value}
                  defaultChecked={answers[step] === data[step].answers[3].value}
                  name="answer"
                  id="d"
                  onChange={check}
                />
                <label htmlFor="d">{data[step].answers[3].answer}</label>
              </label>
            </div>
          </div>
          <div className={styles.btns}>
            <button className={styles.btnsBtn} onClick={back}>
              {t("DoshaTestPage.test.0.back")}
            </button>
            <button
              className={`${styles.btnsBtn} ${styles.btnsBtnActive}`}
              onClick={() => answers[step] && next()}
            >
              {t("DoshaTestPage.test.0.next")}
            </button>
          </div>
        </div>
        <BottomBlobDosha data={step} />
      </Menu>
    );
  } else if (stage === 2 && !isNone) {
    return (
      <Menu title={t("DoshaTestPage.test.1.title")} link="/doshatest2">
        <div className={styles.doshaTest}>
          <div>
            <div className={styles.doshaTestDescription}>
              <div className={styles.doshaTestDescriptionItems}>
                {result &&
                  result
                    .filter(
                      (e) =>
                        e.value ===
                        result.reduce(
                          (max, e) => Math.max(max, e.value),
                          result[0].value
                        )
                    )
                    .splice(0, 1)
                    .map((item: any) => {
                      if (isNaN(item.value)) {
                        return null;
                      } else {
                        return (
                          <div className={styles.doshaTestDescriptionItem}>
                            <ReactSVG
                              src={item.img}
                              style={{ fill: item.color }}
                            />
                            <div>
                              <b>{item.name}</b>
                            </div>
                          </div>
                        );
                      }
                    })}
              </div>
            </div>
            <p className={styles.doshaTestDesc}>
              {t("DoshaTestPage.test.1.summary.text1") +
                " " +
                result[0].name +
                " " +
                t("DoshaTestPage.test.1.summary.text2")}
            </p>
            <div className={styles.dashboardLatest}>
              <div className={styles.dashboardLatestList}>
                {lastRecipes.map((item: any, index: number) => (
                  <a
                    key={"lastRecpies" + index}
                    href={`/recipe?id=${item.id}`}
                    className={styles.dashboardLatestListItem}
                  >
                    <div className={styles.dashboardLatestListItemLove}>
                      {item.isFavorite ? (
                        <ReactSVG src="/assets/icon/love1.svg" />
                      ) : (
                        <ReactSVG src="/assets/icon/love0.svg" />
                      )}
                    </div>
                    <div className={styles.dashboardLatestListItemImg}>
                      <img src={item.media} alt="img" />
                    </div>
                    <p>{item.title}</p>
                  </a>
                ))}
              </div>
            </div>
            <a href="/recipes" style={{ textDecoration: "none" }}>
              <PrimaryButton text={t("DoshaTestPage.test.1.summary.btn")} />
            </a>
          </div>
        </div>
        <BottomBlobDosha />
      </Menu>
    );
  } else if (stage === 2 && isNone) {
    return (
      <Menu title={t("DoshaTestPage.test.1.title")} link="/doshatest2">
        <div className={styles.doshaTest}>
          <div className={styles.doshaTestStart}>
            <h2>{t("DoshaTestPage.test.1.invalid.title")}</h2>
            <PrimaryButton
              text={t("DoshaTestPage.test.1.invalid.btn")}
              onClick={() => {
                setTimeout(() => {
                  history.push("/doshatest2");
                  history.go(0);
                }, 100);
              }}
            />
          </div>
        </div>
        <BottomBlobDosha />
      </Menu>
    );
  } else {
    return (
      <Menu title={t("DoshaTestPage.test.1.title")} goToMenu>
        <div className={styles.doshaTest}>
          <div className={styles.doshaTestStart}>
            <h2>{t("DoshaTestPage.test.1.introduction.title")}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: t("DoshaTestPage.test.1.introduction.desc1"),
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: t("DoshaTestPage.test.1.introduction.desc2"),
              }}
            />

            <PrimaryButton
              text={t("DoshaTestPage.test.1.introduction.start")}
              onClick={() => {
                setAnswers(Array(data.length));
                setStage(1);
              }}
            />
          </div>
        </div>
        <BottomBlobDosha />
      </Menu>
    );
  }
};
