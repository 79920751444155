import { IonContent, IonPage } from "@ionic/react";
import { ErrorLabel } from "../../components/ErrorLabel";
import { Input } from "../../components/Input";
import { PrimaryButton } from "../../components/PrimaryButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./style.module.scss";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { SafeArea } from "capacitor-plugin-safe-area";
import { ReactSVG } from "react-svg";

export const ResetPasswordPage = () => {
  const [success, setSuccess] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("ResetPasswordPage.schema.email"))
      .required(t("ResetPasswordPage.schema.required")),
  });

  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      validationSchema: LoginSchema,
      initialValues: {
        email: "",
      },
      onSubmit: async (values: any) => {
        const formdata = new FormData();
        formdata.append("email", values.email);

        const requestOptions: any = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
        if (
          process.env.REACT_APP_API_URL &&
          process.env.REACT_APP_USER_RESET_PASSWORD
        ) {
          fetch(
            process.env.REACT_APP_API_URL +
              process.env.REACT_APP_USER_RESET_PASSWORD,
            requestOptions
          ).then(async (response) => {
            if (response.status === 200) {
              setInvalidCredentials(false);
              setSuccess(true);
            } else {
              setInvalidCredentials(true);
            }
          });
        }
      },
    }
  );
  const [statusBarHeight, setStatusBarHeight] = useState<any>(0);
  useEffect(() => {
    if (Capacitor.getPlatform() === "ios") {
      SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
        setStatusBarHeight(statusBarHeight);
      });
    }
  }, []);
  return (
    <IonPage id="main-content">
      <div
        style={{
          width: "100%",
          height: `${statusBarHeight}px`,
          backgroundColor: "#000",
        }}
      />
      <IonContent>
        <div className={styles.wrapper}>
          <ReactSVG
            className={styles.cmsBack}
            src="assets/icon/left.svg"
            onClick={() => {
              history.goBack();
            }}
          />
          <div className={styles.login}>
            <img
              className={styles.loginLogo}
              src="/assets/images/logo.png"
              alt="logo"
            />
            <p className={styles.loginSubtitle}>
              {t("ResetPasswordPage.subtitle")}
            </p>
            <img
              className={styles.loginImage}
              src="/assets/images/head.webp"
              alt="img"
            />
            {success ? (
              <h1>{t("ResetPasswordPage.success")}</h1>
            ) : (
              <form onSubmit={handleSubmit} className={styles.loginForm}>
                <Input
                  login
                  type="email"
                  name="email"
                  placeholder={t("ResetPasswordPage.form.email")}
                  onChange={handleChange("email")}
                  onBlur={handleBlur("email")}
                />
                <ErrorLabel
                  error={errors.email || t("ResetPasswordPage.schema.email")}
                  touched={invalidCredentials || touched.email}
                />
                <PrimaryButton text={t("ResetPasswordPage.form.button")} />
              </form>
            )}
          </div>
          <img
            className={styles.loginBlob}
            src="assets/backgrounds/blue_background.svg"
            alt=""
          />
        </div>
      </IonContent>
    </IonPage>
  );
};
