import { useState } from "react";
import styles from "./style.module.scss";
import { ReactSVG } from "react-svg";

export const Input = ({
  type,
  name,
  placeholder,
  onChange,
  onBlur,
  login,
  min,
  step,
  defaultValue,
  settings,
}: {
  type: string;
  name: string;
  placeholder: string;
  onChange?: any;
  onBlur?: any;
  login?: boolean;
  min?: number;
  step?: number;
  defaultValue?: any;
  settings?: boolean;
}) => {
  const [localType, setLocalType] = useState(type);
  const showPassword = (e) => {
    e.preventDefault();
    if (localType === "password") {
      setLocalType("text");
    } else {
      setLocalType(type);
    }
  };

  return (
    <div className={styles.inputWrapper}>
      <input
        className={`${styles.input} ${login ? styles.inputLogin : ""} ${
          settings ? styles.inputSettings : ""
        }`}
        type={localType}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        min={min}
        step={step}
        defaultValue={defaultValue}
      />
      {type === "password" && (
        <div className={styles.showPassword} onClick={showPassword}>
          <ReactSVG
            src={
              localType === "password"
                ? "/assets/icon/eye.svg"
                : "/assets/icon/eye-crossed.svg"
            }
          />
        </div>
      )}
    </div>
  );
};
