import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { LoginPage } from "./pages/LoginPage";
import { DashboardPage } from "./pages/DashboardPage";
import { RegisterPage } from "./pages/RegisterPage";
import { ActivationPage } from "./pages/ActivationPage";
import { SuccessRegisterPage } from "./pages/SuccessRegisterPage";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { NewPasswordPage } from "./pages/NewPasswordPage";
import { SettingsPage } from "./pages/SettingsPage";
import { QuestionnairePage } from "./pages/QuestionnairePage";
import { RecipesPage } from "./pages/RecipesPage";
import { RecipesListingPage } from "./pages/RecipesListingPage";
import { OnboardingPage } from "./pages/OnboardingPage";
import { RootPage } from "./pages/RootPage";
import { DoshaTestPage } from "./pages/DoshaTestPage";
import { DoshaTestPage2 } from "./pages/DoshaTestPage2";
import { RecipePage } from "./pages/RecipePage";
import { FavoritePage } from "./pages/FavoritePage";
import { WeeklyPlanPage } from "./pages/WeeklyPlanPage";
import { SelectDayPage } from "./pages/SelectDayPage";
import { IngredientListPage } from "./pages/IngredientListPage";
import { HealitPage } from "./pages/HealitPage";
import { HealitListingPage } from "./pages/HealitListingPage";
import { HealitItemPage } from "./pages/HealitItemPage";
import { DiscoverListingPage } from "./pages/DiscoverListingPage";
import { DiscoverItemPage } from "./pages/DiscoverItemPage";
import { IngredientAddPage } from "./pages/IngredientAddPage";
import { InformationPage } from "./pages/InformationPage";
import { InformationListingPage } from "./pages/InformationListingPage";
import { TermsPage } from "./pages/TermsPage";
import { RodoPage } from "./pages/RodoPage";
import { PaymentPage } from "./pages/PaymentPage";
import { RecipesListingIngredientsPage } from "./pages/RecipesListingIngredientsPage";
import { LegalNoticePage } from "./pages/LegalNoticePage";

setupIonicReact();

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path="/activation">
            <ActivationPage />
          </Route>
          <Route exact path="/dashboard">
            <DashboardPage />
          </Route>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route exact path="/register">
            <RegisterPage />
          </Route>
          <Route exact path="/successregister">
            <SuccessRegisterPage />
          </Route>
          <Route exact path="/resetpassword">
            <ResetPasswordPage />
          </Route>
          <Route exact path="/newpassword">
            <NewPasswordPage />
          </Route>
          <Route exact path="/settings">
            <SettingsPage />
          </Route>
          <Route exact path="/questionnaire">
            <QuestionnairePage />
          </Route>
          <Route exact path="/recipes">
            <RecipesPage />
          </Route>
          <Route exact path="/recipe">
            <RecipePage />
          </Route>
          <Route exact path="/recipeslisting">
            <RecipesListingPage />
          </Route>
          <Route exact path="/onboarding">
            <OnboardingPage />
          </Route>
          <Route exact path="/doshatest">
            <DoshaTestPage />
          </Route>
          <Route exact path="/doshatest2">
            <DoshaTestPage2 />
          </Route>
          <Route exact path="/favorite">
            <FavoritePage />
          </Route>
          <Route exact path="/weeklyplan">
            <WeeklyPlanPage />
          </Route>
          <Route exact path="/selectday">
            <SelectDayPage />
          </Route>
          <Route exact path="/ingredientlist">
            <IngredientListPage />
          </Route>
          <Route exact path="/ingredientadd">
            <IngredientAddPage />
          </Route>
          <Route exact path="/information">
            <InformationPage />
          </Route>
          <Route exact path="/informationlisting">
            <InformationListingPage />
          </Route>
          <Route exact path="/terms">
            <TermsPage />
          </Route>
          <Route exact path="/legalnotice">
            <LegalNoticePage />
          </Route>
          <Route exact path="/rodo">
            <RodoPage />
          </Route>
          <Route exact path="/healit">
            <HealitPage />
          </Route>
          <Route exact path="/healitlisting">
            <HealitListingPage />
          </Route>
          <Route exact path="/healititem">
            <HealitItemPage />
          </Route>
          <Route exact path="/discover">
            <DiscoverListingPage />
          </Route>
          <Route exact path="/discoveritem">
            <DiscoverItemPage />
          </Route>
          <Route exact path="/payment">
            <PaymentPage />
          </Route>
          <Route exact path="/recipeslistingingredient">
            <RecipesListingIngredientsPage />
          </Route>
          <Route exact path="/">
            <RootPage />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
