import styles from "./style.module.scss";

export const PrimaryButton = ({
  text,
  color,
  onClick,
}: {
  text: string;
  color?: string;
  onClick?: () => void;
}) => {
  return (
    <div className={styles.primaryButton}>
      <button
        type="submit"
        className={styles.primaryButton__btn}
        style={{ backgroundColor: color }}
        onClick={onClick}
      >
        {text}
      </button>
    </div>
  );
};
