import { IonContent, IonPage } from "@ionic/react";
import { Menu } from "../../components/Menu";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { useEffect, useState } from "react";
import { WeeklyPlan } from "../../components/WeeklyPlan";
import { WeeklyPlanSlider } from "../../components/WeeklyPlanSlider";
import {
  checkIsToday,
  checkIsTommorow,
  checkIsYesterday,
} from "../../helpers/time";
import { useHistory } from "react-router";
import { Loader } from "../../components/Loader";

export const DashboardPage = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [lastRecipes, setLastRecipes] = useState([]);
  const getLastRecipes = async () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_RECIPES_LAST) {
      await fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_RECIPES_LAST,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setLastRecipes(res.data);
        }
      });
    }
  };

  const [planRecipes, setPlanRecipes] = useState([]);
  const getPlanRecipes = async () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_RECIPES_PLAN) {
      await fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_RECIPES_PLAN,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          if (res?.data?.plan?.length > 0) {
            res?.data?.plan?.map((item: any) => {
              const day = new Date(item.day.split("-").reverse().join("-"));
              if (checkIsToday(day)) {
                item.dayTitle = t("DashboardPage.today");
                item.today = true;
              } else if (checkIsTommorow(day)) {
                item.dayTitle = t("DashboardPage.tomorrow");
              } else if (checkIsYesterday(day)) {
                item.dayTitle = t("DashboardPage.yesterday");
              } else {
                item.dayTitle = item.day.split("-").reverse().join("-");
              }
            });
            setPlanRecipes(res.data.plan);
          }
        }
      });
    }
  };
  //---  GET USER DATA
  const [showQuestionnaire, setShowQuestionnaire] = useState(true);
  const [name, setName] = useState("");
  const getUserData = async () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_USER_READ) {
      await fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_READ,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          if (
            res?.data?.servings &&
            res?.data?.dosha &&
            res?.data?.reminder &&
            res?.data?.recipeCategory
          ) {
            setShowQuestionnaire(false);
          }
          setName(res.data.name);
          const date = new Date();
          const hour = date.getHours();
          if (hour >= 3 && hour < 12) {
            setName(`${t("DashboardPage.morning")}, ${res.data.name}`);
          } else if (hour >= 12 && hour < 18) {
            setName(`${t("DashboardPage.afternoon")}, ${res.data.name}`);
          } else {
            setName(`${t("DashboardPage.evening")}, ${res.data.name}`);
          }
        }
      });
    }
  };
  //---
  //---  GET QUOTE DATA
  const [quote, setQuote] = useState({ content: "", author: "" });
  const getQuoteData = async () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_QUOTE) {
      await fetch(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_QUOTE,
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setQuote({
            content: res.data.content,
            author: res.data.author,
          });
        }
      });
    }
  };
  //---
  //---  GET BLOG DATA
  const [blog, setBlog] = useState([]);
  const getBlogData = async () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (process.env.REACT_APP_API_URL && process.env.REACT_APP_BLOG_LAST) {
      await fetch(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_BLOG_LAST +
          "?limit=3",
        requestOptions
      ).then(async (response) => {
        if (response.status === 200) {
          const res = await response.json();
          setBlog(res.data);
        }
      });
    }
  };

  const [src, setSrc] = useState<string>("");
  const [style, setStyle] = useState<string>("");

  const blobs = [
    { name: "blue", src: "assets/blobs/vata.png", class: "blob" },
    { name: "yellow", src: "assets/blobs/generic.png", class: "blob__yellow" },
    { name: "green", src: "assets/blobs/kapha.png", class: "blob__green" },
    { name: "red", src: "assets/blobs/pitta.png", class: "blob__red" },
  ];

  function setRandomBlobSrc(): void {
    const randomIndex = Math.floor(Math.random() * blobs.length);
    const randomBlobs = blobs[randomIndex];
    setSrc(randomBlobs.src);
    setStyle(randomBlobs.class);
  }
  const [refresh, setRefresh] = useState(false);
  //---
  const getAllData = async () => {
    setLoading(true);
    await getBlogData();
    await getQuoteData();
    await getUserData();
    await getLastRecipes();
    await getPlanRecipes();
    await setRandomBlobSrc();
    setLoading(false);
  };
  useEffect(() => {
    getAllData();
  }, [refresh]);

  const [view, setView] = useState(1);
  const history = useHistory();
  return (
    <Menu title={name} hide>
      <div className={styles.dashboard}>
        <div className={styles.dashboardTopBtns}>
          <div className={styles.dashboardTopBtnsBtn}>
            {t("DashboardPage.topBtns.cook")}
          </div>
          <div
            className={styles.dashboardTopBtnsBtn}
            onClick={() => {
              history.push("/healit");
              history.go(0);
            }}
          >
            {t("DashboardPage.topBtns.heal")}
          </div>
        </div>
        <div className={styles.dashboardPlanBtns}>
          <a href="/weeklyplan" className={styles.dashboardPlanBtnsHeader}>
            {t("DashboardPage.plan")}
          </a>
          <button
            className={`${styles.dashboardPlanBtnsBtn} ${
              !view && styles.dashboardPlanBtnsBtnActive
            }`}
            onClick={() => {
              setView(0);
              setRefresh(!refresh);
            }}
          >
            <ReactSVG src="/assets/icon/calendarView2.svg" />
          </button>
          <button
            className={`${styles.dashboardPlanBtnsBtn} ${
              view && styles.dashboardPlanBtnsBtnActive
            }`}
            onClick={() => {
              setView(1);
              setRefresh(!refresh);
            }}
          >
            <ReactSVG src="/assets/icon/calendarView1.svg" />
          </button>
        </div>
        <div className={styles.dashboardContent}>
          {view ? (
            <WeeklyPlanSlider
              data={planRecipes}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          ) : (
            <WeeklyPlan
              data={planRecipes}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          )}
        </div>
        <div className={styles.dashboardLatest}>
          <p className={styles.dashboardLatestHeader}>
            {t("DashboardPage.latest")}
          </p>
          <div className={styles.dashboardLatestList}>
            {lastRecipes.map((item: any, index: number) => (
              <a
                key={"lastRecpies" + index}
                href={`/recipe?id=${item.id}`}
                className={styles.dashboardLatestListItem}
              >
                <div className={styles.dashboardLatestListItemLove}>
                  {item.isFavorite ? (
                    <ReactSVG src="/assets/icon/love1.svg" />
                  ) : (
                    <ReactSVG src="/assets/icon/love0.svg" />
                  )}
                </div>
                <div className={styles.dashboardLatestListItemImg}>
                  <img src={item.media} alt="img" />
                </div>
                <p>{item.title}</p>
              </a>
            ))}
          </div>
        </div>
        <div
          style={{ backgroundImage: `url("../../../../${src}")` }}
          className={styles.dashboardCite}
        >
          <p className={styles.dashboardCiteContent}>
            {quote?.content && `"${quote?.content}"`}
          </p>
          <p className={styles.dashboardCiteAuthor}>
            {quote?.author && `${quote?.author}`}
          </p>
        </div>
        {showQuestionnaire && (
          <div className={styles.dashboardQuest}>
            <p className={styles.dashboardQuestHeader}>
              {t("DashboardPage.quest")}
            </p>
            <button
              className={styles.dashboardQuestBtn}
              onClick={() => {
                history.push("/questionnaire");
                history.go(0);
              }}
            >
              <p>{t("DashboardPage.questBtn")}</p>
              <ReactSVG src="/assets/icon/right.svg" />
            </button>
          </div>
        )}
        <div className={styles.dashboardFridge}>
          <img
            className={styles.dashboardFridgeImg}
            src="/assets/images/table.png"
            alt="img"
          />
          <p className={styles.dashboardFridgeHeader}>
            {t("DashboardPage.fridge.header")}
          </p>
          <p className={styles.dashboardFridgeDescription}>
            ~&nbsp;{t("DashboardPage.fridge.description")}&nbsp;~
          </p>
          <button
            className={styles.dashboardFridgeBtn}
            onClick={() => {
              history.push("/recipeslistingingredient");
              history.go(0);
            }}
          >
            <p>{t("DashboardPage.fridge.button")}</p>
            <ReactSVG src="/assets/icon/right.svg" />
          </button>
        </div>
        <div className={styles.dashboardDiscover}>
          <p className={styles.dashboardDiscoverHeader}>
            {t("DashboardPage.discover")}
          </p>
          <div className={styles.dashboardDiscoverList}>
            {blog.map((item: any, index) => (
              <a
                key={"blog" + index}
                href={`/discoveritem?id=${item.id}`}
                className={styles.dashboardDiscoverListItem}
                style={{
                  backgroundImage: `url('${item.media}')`,
                }}
              >
                <p>{item.title}</p>
              </a>
            ))}
          </div>
          <button
            className={styles.dashboardDiscoverBtn}
            onClick={() => {
              history.push("/discover");
              history.go(0);
            }}
          >
            <p>{t("DashboardPage.discoverBtn")}</p>
            <ReactSVG src="/assets/icon/right.svg" />
          </button>
        </div>
        <div className={styles.dashboardTexts}>
          <p className={styles.dashboardTextsHeader}>
            {t("DashboardPage.texts.doshaText")}
          </p>
          <div className={styles.dashboardTextsDosha}>
            <div className={styles.dashboardTextsDoshaItem}>
              <ReactSVG src={"/assets/icon/doshaVata.svg"} />
              <p>{t(`DashboardPage.texts.list.${0}.title`)}</p>
            </div>
            <div className={styles.dashboardTextsDoshaItem}>
              <ReactSVG src={"/assets/icon/doshaPitta.svg"} />
              <p>{t(`DashboardPage.texts.list.${1}.title`)}</p>
            </div>
            <div className={styles.dashboardTextsDoshaItem}>
              <ReactSVG src={"/assets/icon/doshaKapha.svg"} />
              <p>{t(`DashboardPage.texts.list.${2}.title`)}</p>
            </div>
          </div>
          <div className={styles.dashboardTextsList}>
            {[...Array(3)].map((item, index) => (
              <div
                key={"texts" + index}
                className={styles.dashboardTextsListItem}
              >
                <p className={styles.dashboardTextsListItemHeader}>
                  {t(`DashboardPage.texts.list.${index}.title`)}:
                </p>
                <p className={styles.dashboardTextsListItemContent}>
                  {t(`DashboardPage.texts.list.${index}.desc`)}
                </p>
                <img
                  className={styles.dashboardTextsListItemImg}
                  src={t(`DashboardPage.texts.list.${index}.img`)}
                />
              </div>
            ))}
          </div>
          <button
            className={styles.dashboardTextsBtn}
            onClick={() => {
              history.push("/doshatest2");
              history.go(0);
            }}
          >
            <p>{t("DashboardPage.texts.doshaBtn")}</p>
            <ReactSVG src="/assets/icon/right.svg" />
          </button>
        </div>
      </div>
      {loading && <Loader />}
    </Menu>
  );
};
