import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { FCM } from "@capacitor-community/fcm";
import { PushNotifications } from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";
import styles from "./style.module.scss";
import { ReactSVG } from "react-svg";
import { InfinitySpin } from "react-loader-spinner";

export const RootPage = () => {
  const history = useHistory();

  const { i18n } = useTranslation();
  const notificationListener = async () => {
    await PushNotifications.requestPermissions();
    await PushNotifications.register();
    await FCM.setAutoInit({ enabled: true }).catch((err) => console.log(err));
    await FCM.isAutoInitEnabled().catch((err) => console.log(err));
    await FCM.unsubscribeFrom({ topic: "en" }).catch((err) => console.log(err));
    await FCM.unsubscribeFrom({ topic: "de" }).catch((err) => console.log(err));
    await FCM.subscribeTo({ topic: i18n.language }).catch((err) =>
      console.log(err)
    );
    await FCM.getToken().catch((err) => console.log(err));
  };

  useEffect(() => {
    notificationListener();
  }, []);

  useEffect(() => {
    const onBoarding = localStorage.getItem("onBoarding");
    const token = localStorage.getItem("token");
    setTimeout(() => {
    if (!onBoarding && Capacitor.isNativePlatform()) {
      history.push("/onboarding");
    } else if (token) {
      history.push("/dashboard");
    } else {
      history.push("/login");
    }
    }, 2000);
  }, []);
  return (
    <div className={styles.splash}>
      <img src="/assets/images/logoTriconada.png" />
      <InfinitySpin color="#115845" />
    </div>
  );
};
