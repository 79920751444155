import { ReactSVG } from "react-svg";
import styles from "./style.module.scss";
import FlipPage from "react-flip-page";
import { useEffect, useRef, useState } from "react";
import { like } from "../../helpers/like";

export const WeeklyPlanSlider = ({ data, refresh, setRefresh }: any) => {
  const [length, setLength] = useState(0);
  useEffect(() => {
    if (data) setLength(data.length);
  }, [data]);
  const flipPageRef: any = useRef();
  const [position, setPosition] = useState(0);
  return (
    <div className={styles.Container}>
      <button
        className={`${styles.ContainerBtn} ${
          !(position < length - 1) && styles.ContainerBtnHide
        }`}
        onClick={() => {
          setPosition(position + 1);
          flipPageRef.current.gotoNextPage();
        }}
      >
        <ReactSVG src="assets/icon/right.svg" />
      </button>
      <button
        className={`${styles.ContainerBtn} ${
          !(position >= 1) && styles.ContainerBtnHide
        }`}
        onClick={() => {
          setPosition(position - 1);
          flipPageRef.current.gotoPreviousPage();
        }}
      >
        <ReactSVG src="assets/icon/right.svg" />
      </button>
      <button
        className={styles.ItemLove}
        onClick={async () => {
          await like(data[position]?.recipe?.id);
          setRefresh(!refresh);
        }}
      >
        {data[position]?.recipe?.isFavorite ? (
          <ReactSVG src="/assets/icon/love1.svg" />
        ) : (
          <ReactSVG src="/assets/icon/love0.svg" />
        )}
      </button>
      <FlipPage ref={flipPageRef} orientation="horizontal" disableSwipe>
        {data.map((item: any, index: number) => (
          <div
            key={"slider" + index}
            className={styles.Item}
            style={{ backgroundImage: `url(${item.recipe.media})` }}
          >
            <a
              className={styles.ItemLink}
              href={`/recipe?id=${item.recipe.id}`}
            ></a>
            <a
              href={`/recipe?id=${item.recipe.id}`}
              className={styles.ItemMeta}
            >
              <p className={styles.ItemMetaDate}>{item.dayTitle}</p>
              <p className={styles.ItemMetaTitle}>{item.recipe.name} </p>
            </a>
          </div>
        ))}
      </FlipPage>
    </div>
  );
};
