import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { IonContent, IonPage } from "@ionic/react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { PrimaryButton } from "../../components/PrimaryButton";

export const SuccessRegisterPage = () => {
  const { t } = useTranslation();
  const [resend, setResend] = useState(false);
  const resendEmail = (e: any) => {
    e.preventDefault();
    const email: string = localStorage.getItem("email") || "";
    if (email) {
      const formdata = new FormData();

      formdata.append("email", email);

      const requestOptions: any = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      if (process.env.REACT_APP_API_URL && process.env.REACT_APP_USER_RESEND) {
        fetch(
          process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_RESEND,
          requestOptions
        ).then((response) => {
          response.status === 200 ? setResend(true) : setResend(false);
        });
      }
    }
  };
  return (
    <IonPage id="main-content">
      <IonContent className="ion-padding">
        <div className={styles.activation}>
          <img
            className={styles.activationLogo}
            src="/assets/images/logo.png"
            alt="logo"
          />
          <p className={styles.activationSubtitle}>
            {t("SuccessRegisterPage.subtitle")}
          </p>
          <img
            className={styles.activationImage}
            src="/assets/images/head.webp"
            alt="img"
          />

          <h1 className={styles.activationHeader}>
            {t("SuccessRegisterPage.header")}
          </h1>
          <a href="/login" style={{ textDecoration: "none", color: "inherit" }}>
            <PrimaryButton text={t("SuccessRegisterPage.login")} />
          </a>
          {!resend ? (
            <form onSubmit={resendEmail}>
              <PrimaryButton
                text={t("SuccessRegisterPage.resend")}
                color="#FFD89C"
              />
            </form>
          ) : (
            <p className={styles.activationText}>
              {t("SuccessRegisterPage.hasSent")}
            </p>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};
